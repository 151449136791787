import React, { Fragment, useContext } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { MdHistory } from "react-icons/md";
import ConsumerUnitsContext from "./context";
import { StatusBadge } from "components";

const StatusHistory = () => {
  const { editSelected } = useContext(ConsumerUnitsContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Fragment>
      <Button w="100%" variant="outline" rightIcon={<Icon as={MdHistory} />} onClick={onOpen}>
        histórico de status
      </Button>
      <Modal size="6xl" isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Histórico de status</ModalHeader>
          <Divider />
          <ModalBody as={VStack} alignItems="stretch" divider={<StackDivider />}>
            {_.map(editSelected?.statusHistory, (item) => (
              <HStack>
                <Box flex="1">
                  <Text fontSize="xs">Status</Text>
                  <StatusBadge {...item.status} />
                </Box>
                <Box flex="1">
                  <Text fontSize="xs">Substatus</Text>
                  <StatusBadge {...item.substatus} />
                </Box>
                <Box flex="1">
                  <Text fontSize="xs">Alterado em</Text>
                  <Text fontSize="sm">{moment(item.createdAt).format("DD/MM/YYYY [às] HH:mm:ss")}</Text>
                  <Text fontSize="xs">
                    por <strong>{item.createdBy.name}</strong>
                  </Text>
                </Box>
              </HStack>
            ))}
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack} justifyContent="flex-end">
            <Button size="sm" variant="outline" onClick={onClose}>
              fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default StatusHistory;
