import React, { useState, useEffect, useCallback, useContext, Fragment, useRef } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import ObjectID from "bson-objectid";
import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  Switch,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { SyncSelect } from "components";
import { DocumentHistory } from "containers";
import { api, yup } from "lib";
import { messages } from "consts";
import { useArrayItemHandlers, useCustomToast } from "hooks";
import { MdAdd, MdHistory } from "react-icons/md";
import StatusesListContext from "./context";
import { FiTrash } from "react-icons/fi";
import { TbVariable } from "react-icons/tb";

export const colorSchemes = {
  gray: "Cinza",
  red: "Vermelho",
  orange: "Laranja",
  yellow: "Amarelo",
  green: "Verde",
  teal: "Verde-azulado",
  blue: "Azul",
  cyan: "Ciano",
  purple: "Roxo",
  pink: "Rosa",
};

const emailVariables = {
  "{NOME}": "Nome",
  "{DOCUMENTO}": "Documento",
  "{EMAIL}": "E-mail",
  "{TELEFONE_1}": "Telefone 1",
  "{NUM_INSTALACAO}": "Número de instalação",
  "{ENDERECO_LINHA_1}": "Endereço linha 1 (Rua, N°, Compl., Bairro)",
  "{ENDERECO_LINHA_2}": "Endereço linha 1 (Cidade/Estado - CEP)",
};

export const StatusesDetails = () => {
  const { _id } = useParams();
  const { statuses, refreshStatuses, selected, setSelected } = useContext(StatusesListContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const { handleChangeArrayItem, handleAddArrayItem, handleDeleteArrayItem } = useArrayItemHandlers(setFormData);
  const toast = useCustomToast();
  const emailBodyRef = useRef();

  useEffect(() => {
    const formData = selected ?? {};
    setFormData(formData);
  }, [selected]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        data._id ? await api.patch("/private/statuses", [data]) : await api.put("/private/statuses", [data]);
        setSelected();
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshStatuses();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshStatuses, toast]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
        colorScheme: yup.string().required(messages.error.required),
      });
      const filter = formData.parent ? (o) => o.parent === formData.parent : (o) => !o.parent;
      const data = { ...formData, ordination: _.isString(formData._id) ? undefined : _(statuses?.data).filter(filter).size() };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [statuses?.data, formData, handleSaveData]);

  const insetEmailBodyVariable = useCallback(
    (insertText) => {
      const text = formData.email?.body || "";
      const start = emailBodyRef.current.selectionStart;
      const end = emailBodyRef.current.selectionEnd;
      const body = text.slice(0, start) + insertText + text.slice(end);
      setFormData((state) => ({ ...state, email: { ...state.email, body } }));
      setTimeout(() => {
        emailBodyRef.current.setSelectionRange(start + insertText.length, start + insertText.length);
        emailBodyRef.current.focus();
      }, 0);
    },
    [emailBodyRef.current, formData.email?.body]
  );

  return (
    <Drawer size="md" isOpen={_.isObject(selected)} onClose={() => setSelected()}>
      <DrawerOverlay zIndex="1400" />
      <DrawerContent zIndex="1401">
        <DrawerHeader as={HStack}>
          <Box flex="1">
            <Heading size="md">Status</Heading>
            <Text fontSize="sm" fontWeight="light">
              {_id ? selected?.name : "Novo cadastro"}
            </Text>
          </Box>
          <HStack>
            <FormControl display="flex" alignItems="center">
              <FormLabel fontSize="sm" fontWeight="light" mb="0">
                Ativo?
              </FormLabel>
              <Switch
                colorScheme="main"
                isChecked={formData.isActive}
                onChange={() => setFormData((state) => ({ ...state, isActive: !state.isActive }))}
              />
            </FormControl>
          </HStack>
          {formData._id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
        </DrawerHeader>
        <Divider />
        <DrawerBody>
          <Grid templateColumns="repeat(12, 1fr)" gap={4}>
            <GridItem colSpan={12}>
              <FormControl isRequired={true} isInvalid={formErrors.title}>
                <FormLabel fontSize="sm">Título</FormLabel>
                <Input
                  value={formData.title ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))}
                />
                <FormErrorMessage>{formErrors.title}</FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem colSpan={12}>
              <FormControl isRequired={true} isInvalid={formErrors.colorScheme}>
                <FormLabel fontSize="sm">Esquema de cores</FormLabel>
                <SyncSelect
                  value={{ value: formData.colorScheme, label: colorSchemes[formData.colorScheme] }}
                  placeholder="Selecione"
                  options={Object.entries(colorSchemes).map(([value, label]) => ({ value, label }))}
                  onChange={({ value }) => setFormData((state) => ({ ...state, colorScheme: value }))}
                  formatOptionLabel={({ value, label }) => (
                    <HStack>
                      <Box bg={`${value}.500`} w="10px" h="10px" borderRadius="full" />
                      <Text>{label}</Text>
                    </HStack>
                  )}
                />
                <FormErrorMessage>{formErrors.colorScheme}</FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem colSpan={12}>
              <FormControl isInvalid={formErrors.description}>
                <FormLabel fontSize="sm">Descrição</FormLabel>
                <Textarea
                  value={formData.description ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, description: target.value }))}
                />
                <FormErrorMessage>{formErrors.description}</FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>

          {formData.parent && (
            <Fragment>
              <Divider my={6} />

              <Box mb={4}>
                <Heading size="sm">WhatsApp</Heading>
                <Text fontSize="sm">Informações para o envio de mensagem via WhatsApp com o Octadesk.</Text>
              </Box>

              <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                <GridItem colSpan={12}>
                  <FormControl isInvalid={formErrors.whatsapp?.templateId}>
                    <FormLabel fontSize="sm">Id do template</FormLabel>
                    <Input
                      value={formData.whatsapp?.templateId ?? ""}
                      onChange={({ target }) =>
                        setFormData((state) => ({ ...state, whatsapp: { ...state.whatsapp, templateId: target.value } }))
                      }
                    />
                    <FormErrorMessage>{formErrors.whatsapp?.templateId}</FormErrorMessage>
                  </FormControl>
                </GridItem>

                {_.map(formData.whatsapp?.variables, (variable, index) => (
                  <Fragment key={variable._id}>
                    <GridItem colSpan={5}>
                      <FormControl isRequired={true} isInvalid={formErrors.whatsapp?.variables?.[index].key}>
                        <FormLabel fontSize="sm">Variável {index + 1}</FormLabel>
                        <Input
                          value={variable.key ?? ""}
                          onChange={({ target }) => handleChangeArrayItem("whatsapp.variables", index, { key: target.value })}
                        />
                        <FormErrorMessage>{formErrors.whatsapp?.variables?.[index].key}</FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={7}>
                      <FormControl isRequired={true} isInvalid={formErrors.whatsapp?.variables?.[index].value}>
                        <FormLabel fontSize="sm">Valor</FormLabel>
                        <HStack>
                          <Select
                            value={variable.value ?? ""}
                            onChange={({ target }) => handleChangeArrayItem("whatsapp.variables", index, { value: target.value })}
                          >
                            <option value="">--Selecione</option>
                            <optgroup label="Cliente">
                              <option value="customer.name">Nome</option>
                              <option value="customer.document">Documento</option>
                              <option value="customer.email">E-mail</option>
                              <option value="customer.phone1">Telefone 1</option>
                            </optgroup>
                            <optgroup label="Unidade consumidora">
                              <option value="consumerUnit.cemigInstallationNumber">N° de instalação</option>
                              <option value="consumerUnit.address.line1">Endereço linha 1 (Rua, N°, Compl., Bairro)</option>
                              <option value="consumerUnit.address.line2">Endereço linha 1 (Cidade/Estado - CEP)</option>
                            </optgroup>
                          </Select>
                          <IconButton
                            variant="outline"
                            icon={<Icon as={FiTrash} />}
                            onClick={() => handleDeleteArrayItem("whatsapp.variables", index)}
                          />
                        </HStack>
                        <FormErrorMessage>{formErrors.whatsapp?.variables?.[index].value}</FormErrorMessage>
                      </FormControl>
                    </GridItem>
                  </Fragment>
                ))}
              </Grid>

              <Button
                mt={4}
                w="100%"
                variant="outline"
                leftIcon={<Icon as={MdAdd} />}
                onClick={() => handleAddArrayItem("whatsapp.variables", { _id: ObjectID().toHexString(), key: "", value: "" })}
              >
                adicionar variável
              </Button>

              <Divider my={6} />

              <Box mb={4}>
                <Heading size="sm">E-mail</Heading>
                <Text fontSize="sm">Informações para o envio de mensagem via e-mail.</Text>
              </Box>

              <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                <GridItem colSpan={12}>
                  <FormControl isInvalid={formErrors.email?.body}>
                    <HStack mb="5px" justifyContent="space-between">
                      <FormLabel fontSize="sm" mb="0">
                        Corpo da mensagem
                      </FormLabel>
                      <Popover placement="bottom-end">
                        <PopoverTrigger>
                          <Button size="xs" variant="outline" leftIcon={<Icon as={TbVariable} />}>
                            variáveis disponíveis
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverBody as={VStack} alignItems="stretch">
                            {Object.entries(emailVariables).map(([key, label]) => (
                              <Button key={key} size="sm" variant="outline" onClick={() => insetEmailBodyVariable(key)}>
                                {label}
                              </Button>
                            ))}
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </HStack>
                    <Textarea
                      ref={emailBodyRef}
                      h="200px"
                      value={formData.email?.body ?? ""}
                      onChange={({ target }) => setFormData((state) => ({ ...state, email: { ...state.email, body: target.value } }))}
                    />
                    <FormErrorMessage>{formErrors.email?.body}</FormErrorMessage>
                  </FormControl>
                </GridItem>
              </Grid>
            </Fragment>
          )}
        </DrawerBody>
        <Divider />
        <DrawerFooter as={HStack}>
          <Button size="sm" variant="ghost" onClick={() => setSelected()}>
            cancelar
          </Button>
          <Button size="sm" colorScheme="main" onClick={handleSubmit} isLoading={isLoadingSaveData}>
            salvar
          </Button>
        </DrawerFooter>
      </DrawerContent>

      {formData._id && (
        <DocumentHistory
          path={`/private/statuses/${formData._id}/history`}
          isOpen={isOpenDocumentHistory}
          onClose={onCloseDocumentHistory}
        />
      )}
    </Drawer>
  );
};
