export const firebaseAuthErrors = {
  "auth/too-many-requests": "Bloqueamos todas as solicitações deste dispositivo devido a atividades incomuns. Tente mais tarde.",
  "auth/session-expired": "O código SMS expirou. Por favor, reenvie o código de verificação para tentar novamente.",
  "auth/invalid-verification-code":
    "O código de verificação SMS usado para criar a credencial de autenticação do telefone é inválido. Por favor, reenvie o SMS do código de verificação e certifique-se de usar o código de verificação fornecido pelo usuário.",
  "auth/invalid-phone-number":
    "O formato do número de telefone fornecido está incorreto. Insira o número de telefone com DDD e o dígito 9.",
  "auth/missing-client-identifier":
    "Esta solicitação não possui um identificador de aplicativo válido. Por favor, entre em contato com nossa equipe de suporte.",
  "auth/user-disabled": "Sua conta foi desativada. Para mais informações entre em contato com nossa equipe de suporte.",

  "auth/unknown":
    "Problema desconhecido na autenticação. Por favor, tente novamente mais tarde. Se o problema persistir, contate nossa equipe de suporte.",
  "auth/captcha-check":
    "Problema na verificação do captcha. Por favor, tente novamente mais tarde. Se o problema persistir, contate nossa equipe de suporte.",
  "auth/network-request-failed": "Aparentemente sua conexão com a internet está instável.",
  "auth/code-expired": "O código SMS expirou. Por favor, reenvie o código de verificação para tentar novamente.",
  "auth/popup-closed-by-user": "O popup foi cancelado pelo usuário. Por favor, tente novamente.",
  "auth/quota-exceeded": "A conta de uso deste recurso foi esgotada. Por favor, entre em contato com nossa equipe de suporte.",
  "auth/internal-error":
    "Erro interno na autenticação. Por favor, tente novamente mais tarde. Se o problema persistir, contate nossa equipe de suporte.",
  "auth/user-not-found": "Usuário não encontrado. Por favor, entre em contato com nossa equipe de suporte.",
  "auth/credential-already-in-use": "As credenciais informadas já estão em uso. Por favor, entre em contato com nossa equipe de suporte.",
  "auth/captcha-check-failed":
    "Problema na verificação do captcha. Por favor, tente novamente mais tarde. Se o problema persistir, contate nossa equipe de suporte.",
  "auth/cancelled-popup-request": "A requisição do popup foi cancelada pelo usuário. Por favor, tente novamente.",
  "auth/invalid-app-credential": "As credenciais do app informadas são inválidas. Por favor, entre em contato com nossa equipe de suporte.",
  "auth/-":
    "Problema desconhecido sem código na autenticação. Por favor, tente novamente mais tarde. Se o problema persistir, contate nossa equipe de suporte.",
  "auth/tls-ecdhe":
    "Problema desconhecido de TLS na autenticação. Por favor, tente novamente mais tarde. Se o problema persistir, contate nossa equipe de suporte.",
  "auth/wrong-password": "Senha incorreta.",
};
