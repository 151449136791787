import React, { useContext } from "react";
import { FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Input, Select } from "@chakra-ui/react";
import CustomersDetailsContext from "../context";

const Complement = () => {
  const { formData, setFormData, formErrors, isEditDisabled } = useContext(CustomersDetailsContext);

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isInvalid={formErrors.cemigEmail}>
            <FormLabel fontSize="sm">E-mail CEMIG Atende</FormLabel>
            <Input
              value={formData.cemigEmail ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, cemigEmail: target.value }))}
              isDisabled={isEditDisabled("cemigEmail")}
            />
            <FormErrorMessage>{formErrors.cemigEmail}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isInvalid={formErrors.cemigPassword}>
            <FormLabel fontSize="sm">Senha CEMIG Atende</FormLabel>
            <Input
              value={formData.cemigPassword ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, cemigPassword: target.value }))}
              isDisabled={isEditDisabled("cemigPassword")}
            />
            <FormErrorMessage>{formErrors.cemigPassword}</FormErrorMessage>
          </FormControl>
        </GridItem>

        {formData.type === "pf" && (
          <>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl isInvalid={formErrors.maritalStatus}>
                <FormLabel fontSize="sm">Estado civil</FormLabel>
                <Select
                  value={formData.maritalStatus ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, maritalStatus: target.value }))}
                  isDisabled={isEditDisabled("maritalStatus")}
                >
                  <option value="">Selecione</option>
                  <option value="Casado">Casado</option>
                  <option value="Solteiro">Solteiro</option>
                  <option value="Viúvo">Viúvo</option>
                  <option value="Separado">Separado</option>
                  <option value="Desquitado">Desquitado</option>
                </Select>
                <FormErrorMessage>{formErrors.maritalStatus}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 8 }}>
              <FormControl isInvalid={formErrors.profession}>
                <FormLabel fontSize="sm">Profissão</FormLabel>
                <Input
                  value={formData.profession ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, profession: target.value }))}
                  isDisabled={isEditDisabled("profession")}
                />
                <FormErrorMessage>{formErrors.profession}</FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl isInvalid={formErrors.gender}>
                <FormLabel fontSize="sm">Sexo</FormLabel>
                <Select
                  value={formData.gender ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, gender: target.value }))}
                  isDisabled={isEditDisabled("gender")}
                >
                  <option value="female">Feminino</option>
                  <option value="male">Masculino</option>
                  <option value="other">Outro</option>
                </Select>
                <FormErrorMessage>{formErrors.gender}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl isInvalid={formErrors.naturalness}>
                <FormLabel fontSize="sm">Naturalidade</FormLabel>
                <Input
                  value={formData.naturalness ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, naturalness: target.value }))}
                  isDisabled={isEditDisabled("naturalness")}
                />
                <FormErrorMessage>{formErrors.naturalness}</FormErrorMessage>
              </FormControl>
            </GridItem>
          </>
        )}
      </Grid>
    </>
  );
};

export default Complement;
