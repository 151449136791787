import React, { createContext, useEffect, useState, useMemo } from "react";
import _ from "lodash";
import { getAuth, onAuthStateChanged, getIdTokenResult } from "@firebase/auth";
import { Center, Spinner, useMediaQuery } from "@chakra-ui/react";
import { Private, Public } from "pages";
import { InvalidAuthorization, NetworkError, PermissionDenied, AppVersionError } from "exceptions";
import { roles } from "consts";
import SocketProvider from "SocketProvider";

export const AppContext = createContext();

const AppProvider = () => {
  const [currentAuth, setCurrentAuth] = useState();
  const [currentUserClaims, setCurrentUserClaims] = useState();
  const [isMobileView] = useMediaQuery("(max-width: 940px)");
  const currentRole = useMemo(() => {
    const mapped = _.map(currentUserClaims?.roles, (id) => {
      const finded = _.find(roles, (o) => o.id === id);
      return finded;
    });
    return _.maxBy(mapped, (o) => o.hierarchy);
  }, [currentUserClaims?.roles]);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      try {
        if (!user) return;
        await user.reload();
        const { claims } = await getIdTokenResult(user, true);
        setCurrentUserClaims(claims);
      } finally {
        setCurrentAuth(user);
      }
    });
  }, []);

  if (currentAuth === undefined)
    return (
      <Center h="100vh">
        <Spinner />
      </Center>
    );
  return (
    <AppContext.Provider value={{ currentAuth, currentUserClaims, currentRole, isMobileView }}>
      {currentAuth ? (
        <SocketProvider>
          <Private />
        </SocketProvider>
      ) : (
        <Public />
      )}
      <NetworkError />
      <PermissionDenied />
      <InvalidAuthorization />
      <AppVersionError />
    </AppContext.Provider>
  );
};

export default AppProvider;
