import React, { useContext, useEffect, useMemo } from "react";
import _ from "lodash";
import moment from "moment";
import { Badge, Box, Center, HStack, Icon, IconButton, Spinner, StackDivider, Text, VStack } from "@chakra-ui/react";
import { useFetchData } from "hooks";
import { MdOutlineWebhook, MdRefresh } from "react-icons/md";
import ChargesDetailsContext from "./context";

const Webhooks = () => {
  const { formData, setCounters, setIsLoading } = useContext(ChargesDetailsContext);
  const [webhooks, isLoadingWebhooks, refreshWebhooks] = useFetchData(
    useMemo(
      () => ({
        path: "/private/webhooks-find",
        params: { query: { "data.bankSlipId": formData.bankSlipId }, sort: { createdAt: -1 } },
        options: { isEnabled: _.isString(formData.bankSlipId) },
      }),
      [formData.bankSlipId]
    )
  );

  useEffect(() => {
    setCounters((state) => ({ ...state, webhooks: _.size(webhooks?.data) }));
  }, [webhooks?.data]);

  useEffect(() => {
    setIsLoading((state) => ({ ...state, webhooks: isLoadingWebhooks }));
  }, [isLoadingWebhooks]);

  return (
    <>
      <HStack mb="20px">
        <Text flex="1" fontSize="sm" fontWeight="semibold">
          {_.size(webhooks?.data)} registros
        </Text>
        <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingWebhooks} onClick={refreshWebhooks} />
      </HStack>

      <VStack alignItems="stretch" divider={<StackDivider />} spacing={4}>
        {_.map(webhooks?.data, (item) => (
          <HStack key={item._id}>
            <Center w="40px" h="40px" borderRadius="full" bg="main.100">
              <Icon as={MdOutlineWebhook} color="main.500" />
            </Center>
            <Box flex="1">
              <Badge textTransform="none">{item.event}</Badge>
              <Text fontSize="xs">Recebido em {moment(item.createdAt).format("DD/MM/YYYY")}</Text>
            </Box>
          </HStack>
        ))}
      </VStack>

      {isLoadingWebhooks ? (
        <Center p="40px">
          <Spinner />
        </Center>
      ) : (
        _.size(webhooks?.data) === 0 && (
          <Center paddingTop="40px" paddingBottom="20px">
            <Box textAlign="center">
              <Icon as={MdOutlineWebhook} boxSize={20} marginBottom="10px" />
              <Text fontSize="lg" fontWeight="bold">
                Nenhum webhook recebido
              </Text>
              <Text fontSize="sm">Esta cobrança ainda não possui webhooks recebidos.</Text>
            </Box>
          </Center>
        )
      )}
    </>
  );
};

export default Webhooks;
