import React, { useState, useMemo, useCallback, useEffect, useContext } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import * as yup from "yup";
import { messages } from "consts";
import { useFetchData, useCustomToast } from "hooks";
import { SyncSelect } from "components";
import { api } from "lib";
import { MdRefresh } from "react-icons/md";
import CustomersDetailsContext from "../context";
import { BsGrid, BsGrid3X3Gap } from "react-icons/bs";
import { ContractsContext } from "./index";

const AddContract = ({ isOpen, onClose, consumerUnits, onFinish, parent }) => {
  const { _id } = useParams();
  const { formData: customersFormData } = useContext(CustomersDetailsContext);
  const { refreshContracts } = useContext(ContractsContext);
  const { isOpen: isAdvancedMode, onToggle: onToggleAdvancedMode } = useDisclosure();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [contractTemplatesData, isLoadingContractTemplates, refreshContractTemplates, errorContractTemplates] = useFetchData(
    useMemo(() => ({ path: "/private/contract-templates", options: { isEnabled: isOpen } }), [isOpen])
  );
  const contractTemplates = useMemo(() => {
    return _.filter(contractTemplatesData, (o) => {
      const personType = "_".concat(customersFormData.type);
      return o.type === formData.templateType && o.name.toLowerCase().search(personType) !== -1;
    });
  }, [formData.templateType, customersFormData.type, contractTemplatesData]);
  const [isSending, setIsSending] = useState(false);
  const toast = useCustomToast();
  const label = useMemo(() => (parent ? "aditivo" : "contrato"), [parent]);

  useEffect(() => {
    if (errorContractTemplates) toast({ description: errorContractTemplates.message, status: "error", isClosable: true });
  }, [errorContractTemplates]);

  useEffect(() => {
    setFormData({
      type: "digital",
      templateType: "html",
      consumerUnits,
      isManagedUUID: true,
      parent,
    });
    setFormErrors({});
  }, [isOpen, consumerUnits, parent]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsSending(true);
        await api.put(`/private/customers/${_id}/contracts`, data);
        toast({ description: messages.success.createContract, status: "success", isClosable: true });
        onClose();
        refreshContracts();
        onFinish?.();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsSending(false);
      }
    },
    [_id, refreshContracts, onFinish, onClose]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        consumerUnits: yup.array().min(1, messages.error.required),
        type: yup.string().required(messages.error.required),
        isManagedUUID: yup.bool().required(messages.error.required),
        uuid: yup.string().when(["type", "isManagedUUID"], (type, isManagedUUID) => {
          if (type === "digital" && isManagedUUID === false) return yup.string().required(messages.error.required);
        }),
        status: yup.string().when("type", {
          is: "physical",
          then: yup.string().required(messages.error.required),
        }),
        templateType: yup.string().when("isManagedUUID", {
          is: true,
          then: yup.string().required(messages.error.required),
        }),
        template: yup.string().when("isManagedUUID", {
          is: true,
          then: yup.string().required(messages.error.required),
        }),
        name: yup.string().when("isManagedUUID", {
          is: true,
          then: yup.string().required(messages.error.required),
        }),
      });
      const data = { ...formData, consumerUnits: _.map(formData.consumerUnits, "_id") };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <Modal isOpen={isOpen} isCentered={true} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={HStack}>
          <Text flex="1">Adicionar {label}</Text>
          <Button
            size="sm"
            variant="outline"
            leftIcon={<Icon as={isAdvancedMode ? BsGrid : BsGrid3X3Gap} />}
            onClick={onToggleAdvancedMode}
          >
            modo {isAdvancedMode ? "simplificado" : "avançado"}
          </Button>
        </ModalHeader>
        <ModalBody>
          <VStack spacing="20px">
            <FormControl isRequired={true} isInvalid={formErrors.consumerUnits}>
              <FormLabel fontSize="sm">Unidades consumidoras</FormLabel>
              <SyncSelect
                isMulti
                value={formData.consumerUnits ?? {}}
                options={consumerUnits}
                placeholder="Selecione as unidades consumidoras"
                onChange={(consumerUnits) => setFormData((state) => ({ ...state, consumerUnits }))}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ cemigInstallationNumber }) => cemigInstallationNumber}
                isClearable={true}
                isDisabled={_.isString(parent)}
              />
              <FormErrorMessage>{formErrors.consumerUnits}</FormErrorMessage>
            </FormControl>

            {isAdvancedMode && (
              <FormControl isRequired={true} isInvalid={formErrors.type}>
                <FormLabel fontSize="sm">Tipo de {label}</FormLabel>
                <Select
                  value={formData.type ?? ""}
                  onChange={({ target }) =>
                    setFormData((state) => ({
                      ...state,
                      type: target.value,
                      isManagedUUID: target.value === "digital",
                    }))
                  }
                >
                  <option value="digital">Digital</option>
                  <option value="physical">Físico</option>
                </Select>
                <FormErrorMessage>{formErrors.type}</FormErrorMessage>
              </FormControl>
            )}

            {formData.type === "digital" ? (
              <>
                {isAdvancedMode && (
                  <FormControl as={HStack} borderWidth="1px" borderRadius="lg" p="15px">
                    <Box flex="1">
                      <Text fontSize="sm" fontWeight="500">
                        UUID gerenciado?
                      </Text>
                      <FormHelperText fontSize="xs" m="0">
                        Caso você já possua a UUID do {label} desmarque esta opção e informe em seguida o UUID no campo abaixo.
                      </FormHelperText>
                    </Box>
                    <Switch
                      colorScheme="main"
                      isChecked={formData.isManagedUUID}
                      onChange={() => setFormData((state) => ({ ...state, isManagedUUID: !state.isManagedUUID }))}
                    />
                  </FormControl>
                )}

                {formData.isManagedUUID ? (
                  <>
                    {isAdvancedMode && (
                      <FormControl isRequired={true} isInvalid={formErrors.templateType}>
                        <FormLabel fontSize="sm">Tipo do template</FormLabel>
                        <Select
                          value={formData.templateType ?? ""}
                          onChange={({ target }) => setFormData((state) => ({ ...state, templateType: target.value }))}
                        >
                          <option value="html">HTML</option>
                          <option value="word">Word (Docx)</option>
                        </Select>
                        <FormErrorMessage>{formErrors.templateType}</FormErrorMessage>
                      </FormControl>
                    )}

                    <FormControl isRequired={true} isInvalid={formErrors.template}>
                      <FormLabel fontSize="sm">Modelo do {label}</FormLabel>
                      <HStack>
                        <Select
                          value={formData.template ?? ""}
                          onChange={({ target }) => setFormData((state) => ({ ...state, template: target.value }))}
                        >
                          <option value="">--Selecione</option>
                          {_.map(contractTemplates, (item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </Select>
                        <IconButton
                          variant="outline"
                          icon={<Icon as={MdRefresh} />}
                          isLoading={isLoadingContractTemplates}
                          onClick={refreshContractTemplates}
                        />
                      </HStack>
                      <FormErrorMessage>{formErrors.template}</FormErrorMessage>
                    </FormControl>
                    <FormControl isRequired={true} isInvalid={formErrors.name}>
                      <FormLabel fontSize="sm">Nome do {label} (Assunto do e-mail)</FormLabel>
                      <Input
                        value={formData.name ?? ""}
                        onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))}
                      />
                      <FormErrorMessage>{formErrors.name}</FormErrorMessage>
                    </FormControl>
                  </>
                ) : (
                  <FormControl isRequired={true} isInvalid={formErrors.uuid}>
                    <FormLabel fontSize="sm">UUID do {label}</FormLabel>
                    <Input
                      value={formData.uuid ?? ""}
                      onChange={({ target }) => setFormData((state) => ({ ...state, uuid: target.value }))}
                    />
                    <FormErrorMessage>{formErrors.uuid}</FormErrorMessage>
                  </FormControl>
                )}
              </>
            ) : (
              <FormControl isRequired={true} isInvalid={formErrors.status}>
                <FormLabel fontSize="sm">Status</FormLabel>
                <Select
                  value={formData.status ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, status: target.value }))}
                >
                  <option value="">--Selecione</option>
                  <option value="waiting_signers">Aguardando assinaturas</option>
                  <option value="finished">Finalizado</option>
                </Select>
                <FormErrorMessage>{formErrors.status}</FormErrorMessage>
              </FormControl>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter as={HStack}>
          <Button size="sm" variant="outline" onClick={onClose}>
            cancelar
          </Button>
          <Button size="sm" colorScheme="main" isLoading={isSending} onClick={handleSubmit}>
            adicionar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddContract;
