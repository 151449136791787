import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Spinner,
  StackDivider,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { CustomTab, PermissionedContainer } from "components";
import { ChargeUpdate, DocumentHistory } from "containers";
import { useFetchData, useCustomToast, useQueryParams, useWatcherZIndex } from "hooks";
import { MdHistory, MdMoreHoriz, MdOutlineWebhook } from "react-icons/md";
import ChargesDetailsContext from "./context";
import General from "./general";
import Messages from "./messages";
import Webhooks from "./webhooks";
import { BankSlipDownload, BankSlipSend, ChargeCancel } from "containers";
import { VscSend } from "react-icons/vsc";
import { BiListUl, BiSupport } from "react-icons/bi";
import { messages } from "consts";
import { EventEmitter, api } from "lib";

const queryParamsKey = "charge_id";

export const ChargesDetails = () => {
  const { queryParams, setQueryParams } = useQueryParams();
  const _id = useMemo(() => queryParams[queryParamsKey], [queryParams[queryParamsKey]]);
  const zIndex = useWatcherZIndex(queryParamsKey);
  const [data, isLoadingData, refreshData] = useFetchData(useMemo(() => ({ path: `/private/charges/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [counters, setCounters] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const isPending = useMemo(() => _.includes(["created", "updated"], formData.status), [formData.status]);
  const isAllowedRefresh = useMemo(
    () => _.includes(["created", "updated", "updating", "processing", "canceling"], formData.status),
    [formData.status]
  );
  const toast = useCustomToast();

  useEffect(() => {
    setFormData(data ?? {});
  }, [data]);

  useEffect(() => {
    if (_.isString(_id)) onOpen();
    else onClose();
  }, [_id]);

  const handleClose = useCallback(() => {
    delete queryParams[queryParamsKey];
    setQueryParams(queryParams, { replace: true });
  }, [queryParams]);

  const handleChargeRefresh = useCallback(async () => {
    try {
      setIsLoadingRefresh(true);
      await api.patch(`/private/charges/${_id}/refresh`);
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      EventEmitter.emit("charges.refresh");
      handleClose();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingRefresh(false);
    }
  }, [_id, handleClose]);

  return (
    <ChargesDetailsContext.Provider value={{ _id, formData, isLoadingData, refreshData, setCounters, setIsLoading }}>
      <Drawer isOpen={isOpen} size="md" placement="right" blockScrollOnMount={false} onClose={handleClose}>
        <DrawerOverlay zIndex={zIndex.overlay} />
        <DrawerContent zIndex={zIndex.content}>
          <DrawerHeader as={HStack}>
            <HStack flex="1">
              <Text>Cobrança</Text>
              {isLoadingData ? <Spinner size="sm" /> : <Text>#{formData.nid || "-"}</Text>}
            </HStack>
            {isAllowedRefresh && (
              <PermissionedContainer required="charges.update">
                <Tooltip label="Sincronizar cobrança com o banco emissor.">
                  <IconButton
                    size="sm"
                    variant="outline"
                    icon={<Icon as={MdOutlineWebhook} />}
                    isLoading={isLoadingRefresh}
                    onClick={handleChargeRefresh}
                  />
                </Tooltip>
              </PermissionedContainer>
            )}
            {(formData._id === formData.invoice?.charge || isPending) && (
              <Box>
                <Menu>
                  <MenuButton as={Button} size="sm" variant="outline" rightIcon={<Icon as={MdMoreHoriz} />} isLoading={isLoadingData}>
                    mais ações
                  </MenuButton>
                  <MenuList fontSize="sm">
                    <VStack spacing={2} divider={<StackDivider />}>
                      {formData._id === formData.invoice?.charge && (
                        <Fragment>
                          <BankSlipDownload invoice={formData.invoice} />
                          <BankSlipSend appearance="MenuItem" invoice={formData.invoice} charge={formData} customer={formData.customer} />
                        </Fragment>
                      )}
                      {isPending && <ChargeUpdate charge={formData} />}
                      {isPending && <ChargeCancel charge={formData} />}
                    </VStack>
                  </MenuList>
                </Menu>
              </Box>
            )}
            {formData.status === "canceled" && (
              <BankSlipSend
                appearance="Button"
                label="Notificar cancelamento"
                colorScheme="gray"
                variant="outline"
                invoice={formData.invoice}
                charge={formData}
                customer={formData.customer}
              />
            )}
            <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />
          </DrawerHeader>
          <Divider />
          <DrawerBody>
            {formData.sentToCollectionAgency && (
              <HStack bg="purple.100" p="15px" borderRadius="lg" mb={4}>
                <Center bg="purple.500" w="40px" h="40px" borderRadius="full" color="white">
                  <Icon as={BiSupport} boxSize="25px" />
                </Center>
                <Box flex="1">
                  <Heading size="sm">Boleto em cobrança terceirizada</Heading>
                  <Text fontSize="xs">
                    Este boleto foi enviado para cobrança terceirizada em{" "}
                    {moment(formData.sentToCollectionAgencyAt).format("DD/MM/YYYY [às] HH:mm:ss")} por{" "}
                    {formData.sentToCollectionAgencyBy?.name || "-"}
                  </Text>
                </Box>
              </HStack>
            )}

            <Tabs variant="solid-rounded" size="sm" isFitted colorScheme="main">
              <TabList>
                <CustomTab icon={<Icon as={BiListUl} />} title="dados gerais" />
                <CustomTab icon={<Icon as={VscSend} />} title="mensagens" isLoading={isLoading.messages} count={counters.messages} />
                <CustomTab
                  icon={<Icon as={MdOutlineWebhook} />}
                  title="webhooks"
                  isLoading={isLoading.webhooks}
                  count={counters.webhooks}
                />
              </TabList>
              <TabPanels>
                <TabPanel py="20px" px="0">
                  <General />
                </TabPanel>
                <TabPanel py="20px" px="0">
                  <Messages />
                </TabPanel>
                <TabPanel py="20px" px="0">
                  <Webhooks />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>
          <Divider />
          <DrawerFooter>
            <Button size="sm" variant="outline" onClick={handleClose}>
              fechar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <DocumentHistory path={`/private/charges/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />
    </ChargesDetailsContext.Provider>
  );
};
