export const roles = {
  reader: {
    id: "reader",
    hierarchy: 0,
    description: "Concede acesso de leitura ao painel de administração do sistema.",
  },
  partner: {
    id: "partner",
    hierarchy: 1,
    description: "Concede acesso parcial ao painel de administração do sistema.",
  },
  consultant: {
    id: "consultant",
    hierarchy: 2,
    description: "Concede acesso parcial ao painel de administração do sistema.",
  },
  manager: {
    id: "manager",
    hierarchy: 3,
    description: "Concede acesso total ao painel de administração do sistema exceto funções de administrador.",
  },
  admin: {
    id: "admin",
    hierarchy: 4,
    description: "Concede controle total ao painel de administração do sistema.",
  },
};
