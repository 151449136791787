import React, { useState, useMemo, useCallback, useContext, Fragment } from "react";
import _ from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Icon,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { PermissionedContainer } from "components";
import { useCustomToast } from "hooks";
import { api } from "lib";
import { messages } from "consts";
import { BiSupport } from "react-icons/bi";
import ConsumerUnitsContext from "./context";
import CustomersDetailsContext from "../context";

const SentToCollectionAgency = () => {
  const { editSelected, setEditSelected } = useContext(ConsumerUnitsContext);
  const { refreshConsumerUnits } = useContext(CustomersDetailsContext);
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [action, preposition] = useMemo(
    () => (editSelected?.sentToCollectionAgency ? ["remover", "da"] : ["enviar", "para"]),
    [editSelected?.sentToCollectionAgency]
  );
  const toast = useCustomToast();

  const handleSaveData = useCallback(async () => {
    try {
      setIsLoadingSaveData(true);
      const data = { sentToCollectionAgency: !editSelected?.sentToCollectionAgency };
      await api.patch(`/private/consumer-units/${editSelected?._id}/collection-agency`, data);
      setEditSelected((state) => ({ ...state, sentToCollectionAgency: data.sentToCollectionAgency }));
      refreshConsumerUnits();
      onClose();
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingSaveData(false);
    }
  }, [editSelected?._id, editSelected?.sentToCollectionAgency]);

  return (
    <Fragment>
      <PermissionedContainer required="customers.consumerUnits.update.*">
        <Button size="sm" colorScheme="purple" leftIcon={<Icon as={BiSupport} />} onClick={onOpen}>
          {action} {preposition} cobrança terceirizada
        </Button>
      </PermissionedContainer>
      <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Cobrança terceirizada</AlertDialogHeader>
          <AlertDialogBody>
            <Text mb={4}>
              Deseja {action} {preposition} cobrança terceirizada todas as faturas desta Unidade Consumidora?
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" onClick={onClose}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="purple" onClick={handleSaveData} isLoading={isLoadingSaveData}>
              {action} {preposition} cobrança terceirizada
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Fragment>
  );
};

export default SentToCollectionAgency;
