import moment from "moment";
import _ from "lodash";
import { Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { masks, currency, translator, locale } from "lib";
import { StatusBadge } from "components";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => <StatusBadge schema="leads" status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "name",
    title: "Nome/Razão social",
    isVisible: true,
    render: ({ _id, name }) => (
      <Link as={RouterLink} to={`edit/${_id}`}>
        {name}
      </Link>
    ),
    exporter: ({ name }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return name;
      return "****";
    },
  },
  {
    accessor: "document",
    title: "CPF/CNPJ",
    isVisible: true,
    render: ({ document }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.personalData) return masks.document(document);
      return "****";
    },
    exporter: ({ document }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return `'${document || "-"}`;
      return "****";
    },
  },
  {
    accessor: "phone",
    title: "Telefone",
    isVisible: true,
    render: ({ phone }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.personalData) return masks.phone(phone);
      return "****";
    },
    exporter: ({ phone }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return masks.phone(phone);
      return "****";
    },
  },
  {
    accessor: "email",
    title: "E-mail",
    isVisible: true,
    render: ({ email }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.personalData) return email;
      return "****";
    },
    exporter: ({ email }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return email;
      return "****";
    },
  },
  {
    accessor: "electricityBillAmount",
    title: "Valor da conta",
    isVisible: true,
    render: ({ consumerUnits }) => _.map(consumerUnits, (o) => currency(o.electricityBillAmount)).join(" | "),
    exporter: ({ electricityBillAmount }) => currency(electricityBillAmount, { compact: true }),
  },
  {
    accessor: "electricityAverageSpentInKwh",
    title: "Média de consumo (kWh)",
    isVisible: true,
    render: ({ consumerUnits }) => _.map(consumerUnits, (o) => locale(o.electricityAverageSpentInKwh ?? 0)).join(" | "),
    exporter: ({ electricityAverageSpentInKwh }) => locale(electricityAverageSpentInKwh),
  },
  {
    accessor: "address.zipCode",
    title: "CEP",
    isVisible: false,
    formatter: ({ address }) => masks.zipCode(address?.zipCode),
  },
  {
    accessor: "address.street",
    title: "Rua",
    isVisible: false,
    formatter: ({ address }) => address?.street,
  },
  {
    accessor: "address.number",
    title: "Número",
    isVisible: false,
    formatter: ({ address }) => address?.number,
  },
  {
    accessor: "address.complement",
    title: "Complemento",
    isVisible: false,
    formatter: ({ address }) => address?.complement,
  },
  {
    accessor: "address.neighborhood",
    title: "Bairro",
    isVisible: false,
    formatter: ({ address }) => address?.neighborhood,
  },
  {
    accessor: "address.city",
    title: "Cidade",
    isVisible: true,
    formatter: ({ address }) => address?.city,
  },
  {
    accessor: "address.state",
    title: "Estado",
    isVisible: false,
    formatter: ({ address }) => address?.state,
  },
  {
    accessor: "indicatedBy",
    title: "Indicado por",
    isVisible: true,
    render: ({ indicatedBy }) => (
      <Link as={RouterLink} to={`/customers/edit/${indicatedBy?._id}`}>
        {indicatedBy?.name}
      </Link>
    ),
    exporter: ({ indicatedBy }) => indicatedBy?.name,
  },
  {
    accessor: "exhibition",
    title: "Evento",
    isVisible: true,
    formatter: ({ exhibition }) => exhibition?.title,
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    formatter: ({ createdBy }) => createdBy?.name,
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ updatedAt }) => moment(updatedAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
