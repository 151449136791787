import React, { useContext } from "react";
import _ from "lodash";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  StackDivider,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { ErrViewer, StatusBadge } from "components";
import { currency } from "lib";
import { useClipboard } from "hooks";
import { TbExternalLink } from "react-icons/tb";
import ApprovalsDetailsContext from "./context";
import { FiClipboard, FiCopy } from "react-icons/fi";
import ReactJson from "react-json-view";
import { LuListChecks } from "react-icons/lu";
import { MdError } from "react-icons/md";

const General = () => {
  const { formData } = useContext(ApprovalsDetailsContext);
  const copyToClipboard = useClipboard();

  return (
    <VStack alignItems="stretch" divider={<StackDivider />} spacing={4}>
      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Status da aprovação
        </Text>
        <StatusBadge schema="approvals" status={formData.status} />
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Vencimento
        </Text>
        <Text>{moment(formData.dueDate).format("DD/MM/YYYY [às] HH:mm")}</Text>
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Pagamentos
        </Text>
        <Text>{formData.paymentsCount || 0} registros</Text>
        <IconButton
          size="sm"
          variant="outline"
          icon={<Icon as={TbExternalLink} />}
          as={RouterLink}
          to={`/payments?filter_approval_id=${formData._id}`}
        />
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Criação
        </Text>
        <Box textAlign="right">
          <Text>{formData.createdBy?.name}</Text>
          <Text fontSize="xs">{moment(formData.createdAt).format("DD/MM/YYYY [às] HH:mm")}</Text>
        </Box>
      </HStack>

      {formData.validatedAt && (
        <HStack>
          <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
            Validação
          </Text>
          <Box textAlign="right">
            <Text>{formData.validatedBy?.name}</Text>
            <Text fontSize="xs">{moment(formData.validatedAt).format("DD/MM/YYYY [às] HH:mm")}</Text>
          </Box>
        </HStack>
      )}

      {formData.invalidatedAt && (
        <HStack>
          <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
            Invalidação
          </Text>
          <Box textAlign="right">
            <Text>{formData.invalidatedBy?.name}</Text>
            <Text fontSize="xs">{moment(formData.invalidatedAt).format("DD/MM/YYYY [às] HH:mm")}</Text>
          </Box>
        </HStack>
      )}

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          ID da aprovação
        </Text>
        <Text fontSize="xs">{formData.approvalId || "-"}</Text>
        <IconButton size="sm" variant="outline" icon={<Icon as={FiCopy} />} onClick={() => copyToClipboard(formData.approvalId)} />
      </HStack>

      <HStack bg="main.100" color="main.900" p="20px" borderRadius="lg">
        <Text flex="1" whiteSpace="nowrap" fontSize="lg">
          Valor da aprovação
        </Text>
        <Text fontSize="lg">{currency(formData.amount)}</Text>
      </HStack>

      {_.isObject(formData.err) && <ErrViewer err={formData.err} />}
    </VStack>
  );
};

export default General;
