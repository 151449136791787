import React, { useCallback, useState } from "react";
import _ from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Text,
} from "@chakra-ui/react";

export const useConfirmDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [resolvePromise, setResolvePromise] = useState();

  const confirmAction = useCallback(() => {
    return new Promise((resolve) => {
      setIsOpen(true);
      setResolvePromise(() => resolve);
    });
  }, []);

  const handleResolve = useCallback(
    (value) => {
      setIsOpen(false);
      resolvePromise(value);
    },
    [resolvePromise]
  );

  const ConfirmDialog = useCallback(
    ({
      size = "sm",
      title = "Atenção",
      description,
      buttons = [
        { value: false, text: "não" },
        { value: true, text: "sim", colorScheme: "yellow" },
      ],
    }) => (
      <AlertDialog size={size} isOpen={isOpen} closeOnEsc={false} closeOnOverlayClick={false} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            <Text>{title}</Text>
          </AlertDialogHeader>
          <AlertDialogBody>
            <Text>{description}</Text>
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            {buttons.map(({ value, text, ...props }) => (
              <Button size="sm" {...props} onClick={handleResolve.bind(this, value)}>
                {text}
              </Button>
            ))}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    ),
    [isOpen]
  );

  return { confirmAction, ConfirmDialog };
};
