import React, { useMemo, useRef, useState, useCallback } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import _ from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  SlideFade,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Breadcrumb,
  CheckboxBody,
  CheckboxHeader,
  CheckboxProvider,
  ExportCsv,
  Paginator,
  PermissionedContainer,
  Portal,
  TableDrawer,
  TableEmpty,
} from "components";
import { api } from "lib";
import { useFetchData, useCacheState, useClipboard, useCustomToast, useDocumentTitle, usePermissioned, useTable } from "hooks";
import { Content, ContentBody, ContentHeader } from "pages/Private/Container";
import { TbCalendarDue, TbFileExport, TbPigMoney } from "react-icons/tb";
import { MdCheck, MdMoreHoriz } from "react-icons/md";
import { FiClipboard, FiEdit } from "react-icons/fi";
import defaultColumns from "./defaultColumns";
import Filters from "./filters";
import Create from "./create";
import InvoicesListContext from "./context";
import InReview from "./inReview";
import UnifiedInvoiceChangeAlert from "./unifiedInvoiceChangeAlert";
import { BiSupport } from "react-icons/bi";

export const InvoicesList = () => {
  useDocumentTitle("Faturas");
  const location = useLocation();
  const [query, setQuery] = useState();
  const [sort, setSort] = useCacheState(useMemo(() => ({ key: "InvoicesList.sort", defaultValue: { referenceCode: -1 } }), []));
  const [page, setPage] = useCacheState(useMemo(() => ({ key: "InvoicesList.page", defaultValue: 0 }), []));
  const [perPage, setPerPage] = useCacheState(useMemo(() => ({ key: "InvoicesList.perPage", defaultValue: 100 }), []));
  const [search, setSearch] = useCacheState(useMemo(() => ({ key: "InvoicesList.search", defaultValue: "" }), []));
  const [data, isLoadingData, refreshData, errorData, timestampData, fetchAllPages] = useFetchData(
    useMemo(
      () => ({
        path: "/private/invoices",
        params: { query, sort, page, perPage, search },
        options: { isEnabled: _.isObject(query) },
      }),
      [query, sort, page, perPage, search]
    )
  );
  const { columns, cells, updateTableColumns } = useTable(
    useMemo(() => ({ id: location.pathname, defaultColumns, _v: 25 }), [location.pathname])
  );
  const [checkeds, setCheckeds] = useState([]);
  const [isLoadingValidateData, setIsLoadingValidateData] = useState(false);
  const { isOpen: isOpenValidateDialog, onOpen: onOpenValidateDialog, onClose: onCloseValidateDialog } = useDisclosure();
  const { isOpen: isOpenExportData, onOpen: onOpenExportData, onClose: onCloseExportData } = useDisclosure();
  const { isOpen: isOpenCreate, onOpen: onOpenCreate, onClose: onCloseCreate } = useDisclosure();
  const isAllowedExportSensitiveData = usePermissioned("invoices.exportSensitiveData.*");
  const isAllowedReadSensitiveData = usePermissioned("invoices.readSensitiveData.*");
  const checkedsGroupedByStatus = useMemo(() => _.groupBy(checkeds, (o) => o.status), [checkeds]);
  const toast = useCustomToast();
  const copyToClipboard = useClipboard();
  const tableDrawerRef = useRef();

  const handleValidateData = useCallback(async () => {
    try {
      setIsLoadingValidateData(true);
      const data = _.map(checkeds, (o) => ({ _id: o._id, status: "validated" }));
      await api.patch("/private/invoices", data);
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setCheckeds([]);
      refreshData();
      setIsLoadingValidateData(false);
      onCloseValidateDialog();
    }
  }, [checkeds, onCloseValidateDialog, toast, refreshData]);

  const handleTableDrawerChange = useCallback(
    ({ sort, perPage, columns }) => {
      setSort(sort);
      setPerPage(perPage);
      updateTableColumns(columns);
    },
    [updateTableColumns]
  );

  return (
    <InvoicesListContext.Provider value={{ checkeds, setCheckeds, refreshData }}>
      <Content>
        <ContentHeader>
          <HStack justify="space-between">
            <Breadcrumb items={[{ label: "faturas" }, { to: "/invoices", label: "faturas capturadas" }]} />
            <SlideFade in={checkeds.length === 0} hidden={checkeds.length} offsetY="-20px">
              <HStack>
                <PermissionedContainer required="invoices.create">
                  <Button as={RouterLink} to="new" size="sm" colorScheme="main">
                    incluir cadastro
                  </Button>
                </PermissionedContainer>
                <PermissionedContainer required={["invoices.export"]}>
                  <Box>
                    <Menu>
                      <MenuButton as={Button} size="sm" variant="outline" rightIcon={<Icon as={MdMoreHoriz} />}>
                        mais ações
                      </MenuButton>
                      <Portal>
                        <MenuList fontSize="sm">
                          <PermissionedContainer required="invoices.export">
                            <MenuItem onClick={onOpenExportData}>
                              <HStack>
                                <Icon as={TbFileExport} />
                                <Text>exportar faturas</Text>
                              </HStack>
                            </MenuItem>
                          </PermissionedContainer>
                        </MenuList>
                      </Portal>
                    </Menu>
                  </Box>
                </PermissionedContainer>
              </HStack>
            </SlideFade>
          </HStack>

          <PermissionedContainer required="invoices.read">
            <InReview statuses={["validated", "invalidated"]} />
            <InReview statuses={["created", "updated", "update_failed", "paid", "finished", "exempt"]} />
            <UnifiedInvoiceChangeAlert />
          </PermissionedContainer>

          <Heading my="15px" size="md">
            Faturas
          </Heading>
          <Filters
            onSearch={setSearch}
            onQuery={setQuery}
            onRefresh={refreshData}
            onPage={setPage}
            isLoading={isLoadingData}
            onTableDrawer={() => tableDrawerRef.current.open()}
          />
        </ContentHeader>

        <ContentBody>
          <CheckboxProvider key={timestampData} values={checkeds} onChange={setCheckeds}>
            <Table size="sm" whiteSpace="nowrap">
              <Thead>
                <Tr>
                  <Th>
                    <CheckboxHeader />
                  </Th>
                  {cells.map(({ accessor, title }) => (
                    <Th key={accessor}>{title}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {_.map(data?.data, (item) => (
                  <Tr
                    key={item._id}
                    cursor="pointer"
                    _hover={{ _light: { bg: "gray.50" }, _dark: { bg: "gray.700" } }}
                    _active={{ _light: { bg: "gray.100" }, _dark: { bg: "gray.900" } }}
                    onDoubleClick={() => window.open(`/invoices/details/${item._id}`)}
                  >
                    <Td>
                      <HStack>
                        <CheckboxBody value={item} />
                        <Box>
                          <Menu placement="right-start">
                            <MenuButton as={IconButton} size="xs" colorScheme="main" icon={<Icon as={MdMoreHoriz} />} />
                            <MenuList>
                              <MenuGroup title={item.name} pb="5px">
                                <MenuItem icon={<Icon as={FiClipboard} />} onClick={() => copyToClipboard(item._id)}>
                                  copiar código
                                </MenuItem>
                                <PermissionedContainer required="invoices.update">
                                  <MenuItem icon={<Icon as={FiEdit} />} as={RouterLink} to={`details/${item._id}`}>
                                    editar
                                  </MenuItem>
                                </PermissionedContainer>
                              </MenuGroup>
                            </MenuList>
                          </Menu>
                        </Box>
                        {item.isOverdue && (
                          <Tooltip label="Atrasado">
                            <Center bg="red.500" w="25px" h="25px" borderRadius="full">
                              <Icon as={TbCalendarDue} color="white" />
                            </Center>
                          </Tooltip>
                        )}
                        {item.sentToCollectionAgency && (
                          <Tooltip label="Cobrança terceirizada">
                            <Center bg="purple.500" w="25px" h="25px" borderRadius="full">
                              <Icon as={BiSupport} color="white" />
                            </Center>
                          </Tooltip>
                        )}
                      </HStack>
                    </Td>
                    {cells.map(({ accessor, formatter, render }) => (
                      <Td key={accessor}>{formatter?.(item) ?? render?.(item, { isAllowedReadSensitiveData })}</Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
              <Tfoot _light={{ bg: "gray.50" }} _dark={{ bg: "gray.900" }} fontWeight="bold">
                <Tr>
                  <Td></Td>
                  {cells.map(({ accessor, renderFoot }) => (
                    <Td key={accessor}>{renderFoot?.(data?.footer ?? {})}</Td>
                  ))}
                </Tr>
              </Tfoot>
            </Table>
            {isLoadingData && (
              <Center p="30px">
                <Spinner />
              </Center>
            )}
            <TableEmpty isLoading={isLoadingData} size={_.size(data?.data)} />
          </CheckboxProvider>
        </ContentBody>
      </Content>

      <Divider />

      <SlideFade in={checkeds.length} hidden={checkeds.length === 0} offsetY="20px">
        <HStack p="20px" justify="space-between">
          <Text>{checkeds.length} selecionados</Text>
          <HStack>
            <Button
              size="sm"
              variant="outline"
              leftIcon={<Icon as={TbPigMoney} />}
              isDisabled={checkeds.length === 0 || _.size(checkedsGroupedByStatus.validated) !== checkeds.length}
              onClick={onOpenCreate}
            >
              emitir cobranças
            </Button>
            <Button
              size="sm"
              variant="outline"
              leftIcon={<Icon as={MdCheck} />}
              isDisabled={checkeds.length === 0 || _.size(checkedsGroupedByStatus.analyzing) !== checkeds.length}
              onClick={onOpenValidateDialog}
            >
              validar faturas
            </Button>
          </HStack>
        </HStack>
      </SlideFade>

      <SlideFade in={checkeds.length === 0} hidden={checkeds.length} offsetY="20px">
        <Box p="20px">
          <Paginator loading={isLoadingData} page={page} size={data?.size} perPage={perPage} onPaginate={setPage} />
        </Box>
      </SlideFade>

      <TableDrawer
        ref={tableDrawerRef}
        defaultColumns={defaultColumns}
        columns={columns}
        sort={sort}
        perPage={perPage}
        onChange={handleTableDrawerChange}
      />

      <ExportCsv
        filename="faturas"
        onFetchData={fetchAllPages}
        columns={columns}
        isOpen={isOpenExportData}
        onClose={onCloseExportData}
        isAllowedExportSensitiveData={isAllowedExportSensitiveData}
      />

      <AlertDialog isOpen={isOpenValidateDialog} onClose={onCloseValidateDialog} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>Deseja realmente validar as faturas selecionadas?</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button onClick={onCloseValidateDialog}>cancelar</Button>
            <Button colorScheme="green" onClick={handleValidateData} isLoading={isLoadingValidateData}>
              confirmar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <Create isOpen={isOpenCreate} onClose={onCloseCreate} />
    </InvoicesListContext.Provider>
  );
};
