const terms = {
  reader: "Leitor",
  partner: "Parceiro",
  consultant: "Consultor",
  manager: "Gerente",
  admin: "Administrador",
  day: "Dia",
  week: "Semana",
  month: "Mês",
  registered: "Cadastrado",
  under_review: "Em análise",
  sended_to_cemig: "Enviado para a CEMIG",
  connected: "Conectado",
  processing: "Processando",
  waiting_signers: "Aguardando signatários",
  waiting_signatures: "Aguardando assinaturas",
  finished: "Finalizado",
  archived: "Arquivado",
  canceled: "Cancelado",
  editing: "Editando",
  waiting: "Aguardando",
  transformed: "Transformado",
  digital: "Digital",
  physical: "Físico",
  low: "Baixa",
  high: "Alta",
  direct_debit: "Débito automático",
  cash: "Dinheiro",
  pix: "Pix",
  email: "E-mail",
  whatsapp: "WhatsApp",
  phone: "Telefone",
  cellphone: "Celular",
  others: "Outros",
  prospection: "Prospecção",
  negotiation: "Negociação",
  archive: "Arquivo",
  analyzing: "Em análise",
  validated: "Validado",
  invalidated: "Invalidado",
  created: "Emitido",
  paid: "Pago",
  canceled: "Cancelado",
  expired: "Vencido",
  processing: "Processando",
  failed: "Falha",
  updated: "Atualizado",
  updating: "Atualizando",
  cancel_failed: "Falha no cancelamento",
  returned: "Estornado",
  canceling: "Cancelando",
  fixed_tariff: "Tarifa fixa",
  full_tariff: "Tarifa cheia",
  compensable_tariff: "Tarifa compensável",
  receiver: "Recebedora",
  generator: "Geradora",
  authorized: "Autorizada",
  cancelled: "Cancelada",
  confirmed: "Confirmado",
  processed: "Processado",
  adjourned: "Retentativa",
  reverted: "Estornado",
  scheduled: "Agendado",
  waiting_approval: "Aguardando aprovação",
  waiting_analysis: "Aguardando análise",
  approval_cancelled: "Aprovação cancelada",
  approval_failed: "Falha na aprovação",
  ignored: "Ignorada",
  outdated: "Desatualizada",
  in_progress: "Em progresso",
  success: "Sucesso",
  retried: "Retentativa",
  warning: "Aviso",
  fixed: "Fixo",
  dynamic: "Dinâmico",
  female: "Feminino",
  male: "Masculino",
  exempt: "Isenta",
  unqualified: "Não qualificada",
  aborted: "Abortado",
  unconfirmed_communication: "Comunicação não confirmada",
  confirmed_communication: "Comunicação confirmada",
  sent_for_connection: "Enviado para conexão",
  waiting_for_disconnection: "Aguardando desconexão",
  suspect: "Suspeito",
  waiting_cancellation: "Aguardando cancelamento",
  issue_failed: "Falha na emissão",
  update_failed: "Falha na atualização",
  pending: "Pendente",
  warned: "Alertado",
  sending: "Enviando",
  bounce: "Retornou",
  complaint: "Reclamação",
  delivery: "Entregue",
  error: "Erro",
  timeout: "Tempo esgotado",
  delivered: "Entregue",
  solved: "Solucionado",
  danger: "Perigo",
  warning: "Atenção",
  info: "Informação",
  sent: "Enviado",
};

export const translator = (word) => {
  const term = word?.toLowerCase?.();
  if (terms[term]) return terms[term];
  return word;
};
