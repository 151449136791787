import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  InputRightElement,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { api, translator, setOneOrMany } from "lib";
import { AsyncSelect, SyncSelect, RangeDateInput, InputCurrency, RangeInput } from "components";
import { statuses } from "consts";
import { useFetchData, useCacheState, useStickyState, useNewTabState } from "hooks";
import { MdClose, MdSearch, MdRefresh } from "react-icons/md";
import { HiOutlineFilter } from "react-icons/hi";
import { BiCog } from "react-icons/bi";

let loadCustomersTimeout, loadPromotionsTimeout, loadTarifGroupsTimeout, loadPowerPlantsTimeout;
let loadUsersTimeout = {};

const invoiceReceiptMethods = [{ value: "whatsapp" }, { value: "email" }, { value: "others" }];

const Filters = ({ onQuery, onRefresh, onPage, isLoading, onTableDrawer }) => {
  const location = useLocation();
  const newTabState = useNewTabState();
  const [query, setQuery] = useStickyState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.query"),
        defaultValue: newTabState ?? location.state ?? {},
        useCached: _.isObject(newTabState) === false && _.isObject(location.state) === false,
        _v: 2,
        processor: (data) => ({
          ...data,
          messagedAtStart: data.messagedAtStart && moment(data.messagedAtStart).toDate(),
          messagedAtEnd: data.messagedAtEnd && moment(data.messagedAtEnd).toDate(),
          referenceDateStart: data.referenceDateStart && moment(data.referenceDateStart).toDate(),
          referenceDateEnd: data.referenceDateEnd && moment(data.referenceDateEnd).toDate(),
          issueDateStart: data.issueDateStart && moment(data.issueDateStart).toDate(),
          issueDateEnd: data.issueDateEnd && moment(data.issueDateEnd).toDate(),
          dueDateStart: data.dueDateStart && moment(data.dueDateStart).toDate(),
          dueDateEnd: data.dueDateEnd && moment(data.dueDateEnd).toDate(),
          analyzedAtStart: data.analyzedAtStart && moment(data.analyzedAtStart).toDate(),
          analyzedAtEnd: data.analyzedAtEnd && moment(data.analyzedAtEnd).toDate(),
          createdAtStart: data.createdAtStart && moment(data.createdAtStart).toDate(),
          createdAtEnd: data.createdAtEnd && moment(data.createdAtEnd).toDate(),
          paidAtStart: data.paidAtStart && moment(data.paidAtStart).toDate(),
          paidAtEnd: data.paidAtEnd && moment(data.paidAtEnd).toDate(),
          finishedAtStart: data.finishedAtStart && moment(data.finishedAtStart).toDate(),
          finishedAtEnd: data.finishedAtEnd && moment(data.finishedAtEnd).toDate(),
        }),
      }),
      [location.pathname, location.state, newTabState]
    )
  );
  const [isOpen, setIsOpen] = useCacheState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.isOpen"),
        defaultValue: false,
      }),
      []
    )
  );
  const [formData, setFormData] = useState(query);
  const [isFiltering, setIsFiltering] = useState(false);
  const backgroundColor = useColorModeValue("gray.50", "blackAlpha.300");
  const containerProps = useMemo(
    () => isOpen && { padding: { base: "10px", lg: "20px" }, backgroundColor, marginBottom: 8 },
    [isOpen, backgroundColor]
  );
  const [subclasses, isLoadingSubclasses, refreshSubclasses] = useFetchData(
    useMemo(
      () => ({
        path: "/private/invoices/distributor/subclasses",
        params: { perPage: -1, isAutocomplete: true },
        options: { isEnabled: true, formatter: (data) => _.map(data, (value) => ({ value, label: value })) },
      }),
      []
    )
  );
  const [classes, isLoadingClasses, refreshClasses] = useFetchData(
    useMemo(
      () => ({
        path: "/private/invoices/distributor/classes",
        params: { perPage: -1, isAutocomplete: true },
        options: { isEnabled: true, formatter: (data) => _.map(data, (value) => ({ value, label: value })) },
      }),
      []
    )
  );

  useEffect(() => {
    const response = {};
    const mapObjectId = (data) => _.map(data, ({ _id }) => ["@ObjectId", _id]);

    setOneOrMany("invoice.referenceCode", query.referenceCode, response);
    setOneOrMany("invoice.nid", query.nid, response, (v) => parseInt(v));
    if (query.status?.length) _.set(response, "invoice.status.$in", _.map(query.status, "value"));

    setOneOrMany(`consumerUnit.cemigInstallationNumber`, query.installationNumber, response);
    if (query.distributorClass?.length) _.set(response, `invoice["distributor.current.class"].$in`, _.map(query.distributorClass, "value"));
    if (query.distributorSubclass?.length)
      _.set(response, `invoice["distributor.current.subclass"].$in`, _.map(query.distributorSubclass, "value"));
    if (query.distributorPromotion?.length)
      _.set(response, `invoice["promotionDiscount.promotion"].$in`, mapObjectId(query.distributorPromotion));
    if (query.distributorAmountStart) _.set(response, `invoice["distributor.current.amount"].$gte`, query.distributorAmountStart);
    if (query.distributorAmountEnd) _.set(response, `invoice["distributor.current.amount"].$lte`, query.distributorAmountEnd);

    if (query.distributorDigitableLine) {
      if (query.distributorDigitableLine === "yes") _.set(response, `invoice["distributor.current.digitableLine"].$ne`, "");
      else _.set(response, `invoice["distributor.current.digitableLine"]`, "");
    }

    if (query.distributorPaymentMethod) {
      switch (query.distributorPaymentMethod) {
        case "pix":
          _.set(response, `invoice["distributor.current.isPix"]`, true);
          break;
        case "direct_debit":
          _.set(response, `invoice["distributor.current.isDirectDebit"]`, true);
          break;
        case "unspecified":
          _.set(response, "invoice.$and", [
            { $or: [{ "distributor.current.isPix": false }, { "distributor.current.isPix": { $exists: false } }] },
            { $or: [{ "distributor.current.isDirectDebit": false }, { "distributor.current.isDirectDebit": { $exists: false } }] },
          ]);
          break;
      }
    }

    if (query.referenceDateStart) _.set(response, `invoice.referenceDate.$gte`, ["@ISODate", query.referenceDateStart]);
    if (query.referenceDateEnd) _.set(response, `invoice.referenceDate.$lte`, ["@ISODate", query.referenceDateEnd]);
    if (query.issueDateStart) _.set(response, `invoice.issueDate.$gte`, ["@ISODate", query.issueDateStart]);
    if (query.issueDateEnd) _.set(response, `invoice.issueDate.$lte`, ["@ISODate", query.issueDateEnd]);
    if (query.dueDateStart) _.set(response, `invoice.dueDate.$gte`, ["@ISODate", query.dueDateStart]);
    if (query.dueDateEnd) _.set(response, `invoice.dueDate.$lte`, ["@ISODate", query.dueDateEnd]);
    if (query.powerPlant?.length) _.set(response, "invoice.powerPlant.$in", mapObjectId(query.powerPlant));
    if (query.tariffGroup?.length) _.set(response, "invoice.tariffGroup.$in", mapObjectId(query.tariffGroup));
    if (query.createdBy?.length) _.set(response, "invoice.createdBy.$in", mapObjectId(query.createdBy));
    if (query.analyzedBy?.length) _.set(response, "invoice.analyzedBy.$in", mapObjectId(query.analyzedBy));
    if (query.sendingMethod?.length) _.set(response, "invoice.sendingMethod.$in", _.map(query.sendingMethod, "value"));
    if (query.isUnified?.length) _.set(response, "invoice.isUnified", query.isUnified === "yes");
    if (query.sentToCollectionAgency?.length) _.set(response, "invoice.sentToCollectionAgency", query.sentToCollectionAgency === "yes");
    if (query.discountPercentageStart) _.set(response, "invoice.discountPercentage.$gte", query.discountPercentageStart);
    if (query.discountPercentageEnd) _.set(response, "invoice.discountPercentage.$lte", query.discountPercentageEnd);
    if (query.amountStart) _.set(response, "invoice.amount.$gte", query.amountStart);
    if (query.amountEnd) _.set(response, "invoice.amount.$lte", query.amountEnd);
    if (query.receivableAmountStart) _.set(response, "invoice.receivableAmount.$gte", query.receivableAmountStart);
    if (query.receivableAmountEnd) _.set(response, "invoice.receivableAmount.$lte", query.receivableAmountEnd);
    if (query.analyzedAtStart) _.set(response, "invoice.analyzedAt.$gte", ["@ISODate", query.analyzedAtStart]);
    if (query.analyzedAtEnd) _.set(response, "invoice.analyzedAt.$lte", ["@ISODate", query.analyzedAtEnd]);
    if (query.createdAtStart) _.set(response, "invoice.createdAt.$gte", ["@ISODate", query.createdAtStart]);
    if (query.createdAtEnd) _.set(response, "invoice.createdAt.$lte", ["@ISODate", query.createdAtEnd]);
    if (query.paidAtStart) _.set(response, "invoice.paidAt.$gte", ["@ISODate", query.paidAtStart]);
    if (query.paidAtEnd) _.set(response, "invoice.paidAt.$lte", ["@ISODate", query.paidAtEnd]);
    if (query.finishedAtStart) _.set(response, "invoice.finishedAt.$gte", ["@ISODate", query.finishedAtStart]);
    if (query.finishedAtEnd) _.set(response, "invoice.finishedAt.$lte", ["@ISODate", query.finishedAtEnd]);
    if (query.isFirstInvoiceIssue?.length) _.set(response, "invoice.isFirstInvoiceIssue", query.isFirstInvoiceIssue === "yes");
    if (query.isFirstBankSlipIssue?.length) _.set(response, "invoice.isFirstBankSlipIssue", query.isFirstBankSlipIssue === "yes");
    if (query.messagedAtStart) _.set(response, "invoice.messagedAt.$gte", ["@ISODate", query.messagedAtStart]);
    if (query.messagedAtEnd) _.set(response, "invoice.messagedAt.$lte", ["@ISODate", query.messagedAtEnd]);
    if (query.isMessaged?.length) _.set(response, "invoice.messagedAt.$exists", query.isMessaged === "yes");
    if (query.inReview?.length) _.set(response, `invoice["distributor.waiting"].$exists`, query.inReview === "yes");
    if (query.isLowIncome?.length) _.set(response, `invoice["distributor.current.isLowIncome"]`, query.isLowIncome === "yes");
    if (query.hasPayment?.length) _.set(response, `invoice.payment.$exists`, query.hasPayment === "yes");

    if (query.customer?.length) _.set(response, "customer._id.$in", mapObjectId(query.customer));
    if (query.customerConsultant?.length) _.set(response, "customer.consultant.$in", mapObjectId(query.customerConsultant));

    setIsFiltering(Object.keys(response).length > 0);
    onQuery(response);
    onPage(0);
  }, [onQuery, onPage, query, location.state]);

  const handleLoadCustomers = useCallback((search, cb) => {
    clearTimeout(loadCustomersTimeout);
    loadCustomersTimeout = setTimeout(async () => {
      const response = await api.post("/private/customers", { search, perPage: 20, isAutocomplete: true });
      cb(_.map(response?.data, ({ _id, name }) => ({ _id, name })));
    }, 1000);
  }, []);

  const handleLoadUsers = useCallback((key) => {
    return (search, cb) => {
      clearTimeout(loadUsersTimeout[key]);
      loadUsersTimeout[key] = setTimeout(async () => {
        const response = await api.post("/private/users", { search, perPage: 20, isAutocomplete: true });
        cb(_.map(response?.data, ({ _id, name }) => ({ _id, name })));
      }, 1000);
    };
  }, []);

  const handleLoadPromotions = useCallback((search, cb) => {
    clearTimeout(loadPromotionsTimeout);
    loadPromotionsTimeout = setTimeout(async () => {
      const response = await api.post("/private/promotions", { search, perPage: 20, isAutocomplete: true });
      cb(_.map(response?.data, ({ _id, title }) => ({ _id, title })));
    }, 1000);
  }, []);

  const handleLoadPowerPlants = useCallback((search, cb) => {
    clearTimeout(loadPowerPlantsTimeout);
    loadPowerPlantsTimeout = setTimeout(async () => {
      const response = await api.post("/private/power-plants", { search, perPage: 20, isAutocomplete: true });
      cb(_.map(response?.data, ({ _id, name }) => ({ _id, name })));
    }, 1000);
  }, []);

  const handleLoadTariffGroups = useCallback((search, cb) => {
    clearTimeout(loadTarifGroupsTimeout);
    loadTarifGroupsTimeout = setTimeout(async () => {
      const response = await api.post("/private/tariff-groups", { search, perPage: 20, isAutocomplete: true });
      cb(_.map(response?.data, ({ _id, title }) => ({ _id, title })));
    }, 1000);
  }, []);

  const handleSubmit = useCallback(() => {
    setQuery(formData);
  }, [setQuery, formData]);

  const handleClean = useCallback(() => {
    setQuery({});
    setFormData({});
  }, [setQuery, setFormData]);

  return (
    <Box {...containerProps} borderRadius="lg" transition="400ms">
      <Grid templateColumns="repeat(12,1fr)" gap={2} mb={8}>
        <GridItem as={HStack} justifyContent="space-between" colSpan={{ base: 12, lg: 12 }}>
          <HStack>
            <Button
              colorScheme={isFiltering ? "main" : "gray"}
              variant="outline"
              rightIcon={<Icon as={HiOutlineFilter} />}
              onClick={() => setIsOpen((state) => !state)}
            >
              filtros
            </Button>
            {isFiltering && (
              <Button variant="outline" rightIcon={<Icon as={MdClose} />} onClick={handleClean}>
                limpar filtros
              </Button>
            )}
          </HStack>
          <HStack>
            <IconButton variant="outline" icon={<Icon as={MdRefresh} />} fontSize="sm" isLoading={isLoading} onClick={onRefresh} />
            <IconButton fontSize="sm" variant="outline" icon={<Icon as={BiCog} />} onClick={onTableDrawer} />
          </HStack>
        </GridItem>
      </Grid>
      {isOpen && (
        <>
          <Grid templateColumns="repeat(12, 1fr)" gap={4}>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Consultor
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.customerConsultant ?? []}
                  defaultOptions
                  loadOptions={handleLoadUsers("customerConsultant")}
                  placeholder="Selecione"
                  onChange={(customerConsultant) => setFormData((state) => ({ ...state, customerConsultant }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ name }) => name}
                  isClearable={true}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  NID
                </FormLabel>
                <Input
                  size="sm"
                  variant="filled"
                  value={formData.nid ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, nid: target.value }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Cód. referência
                </FormLabel>
                <Input
                  size="sm"
                  variant="filled"
                  value={formData.referenceCode ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, referenceCode: target.value }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  N° de instalação
                </FormLabel>
                <Input
                  size="sm"
                  variant="filled"
                  value={formData.installationNumber ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, installationNumber: target.value }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Cliente
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.customer ?? []}
                  defaultOptions
                  loadOptions={handleLoadCustomers}
                  placeholder="Selecione"
                  onChange={(customer) => setFormData((state) => ({ ...state, customer }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ name }) => name}
                  isClearable={true}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Status
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  withSelectAll
                  value={formData.status ?? []}
                  placeholder="Selecione"
                  options={statuses.invoices}
                  onChange={(status) => setFormData((state) => ({ ...state, status }))}
                  formatOptionLabel={({ color, value, label }) => (
                    <HStack>
                      <Box bg={color} w="10px" h="10px" borderRadius="full" />
                      <Text>{label || translator(value)}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Classe
                </FormLabel>
                <HStack>
                  <SyncSelect
                    size="sm"
                    variant="filled"
                    isMulti
                    withSelectAll
                    value={formData.distributorClass ?? []}
                    placeholder="Selecione"
                    options={classes}
                    onChange={(data) => setFormData((state) => ({ ...state, distributorClass: data }))}
                  />
                  <IconButton size="sm" icon={<Icon as={MdRefresh} />} isLoading={isLoadingClasses} onClick={refreshClasses} />
                </HStack>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Subclasse
                </FormLabel>
                <HStack>
                  <SyncSelect
                    size="sm"
                    variant="filled"
                    isMulti
                    withSelectAll
                    value={formData.distributorSubclass ?? []}
                    placeholder="Selecione"
                    options={subclasses}
                    onChange={(distributorSubclass) => setFormData((state) => ({ ...state, distributorSubclass }))}
                  />
                  <IconButton size="sm" icon={<Icon as={MdRefresh} />} isLoading={isLoadingSubclasses} onClick={refreshSubclasses} />
                </HStack>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Grupo tarifário
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.tariffGroup ?? []}
                  defaultOptions
                  loadOptions={handleLoadTariffGroups}
                  placeholder="Selecione"
                  onChange={(tariffGroup) => setFormData((state) => ({ ...state, tariffGroup }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ title }) => title}
                  isClearable={true}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Promoção
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.distributorPromotion ?? []}
                  defaultOptions
                  loadOptions={handleLoadPromotions}
                  placeholder="Selecione"
                  onChange={(distributorPromotion) => setFormData((state) => ({ ...state, distributorPromotion }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ title }) => title}
                  isClearable={true}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Desconto
                </FormLabel>
                <RangeInput
                  as={InputCurrency}
                  defaultStartValue={formData.discountPercentageStart}
                  defaultEndValue={formData.discountPercentageEnd}
                  onChange={({ startValue, endValue }) =>
                    setFormData((state) => ({ ...state, discountPercentageStart: startValue, discountPercentageEnd: endValue }))
                  }
                  precision={0}
                  isPercentage={true}
                  InputRightElement={<InputRightElement fontSize="xs">%</InputRightElement>}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Valor CEMIG
                </FormLabel>
                <RangeInput
                  as={InputCurrency}
                  defaultStartValue={formData.distributorAmountStart}
                  defaultEndValue={formData.distributorAmountEnd}
                  onChange={({ startValue, endValue }) =>
                    setFormData((state) => ({ ...state, distributorAmountStart: startValue, distributorAmountEnd: endValue }))
                  }
                  InputRightElement={<InputRightElement fontSize="xs">R$</InputRightElement>}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Valor Click
                </FormLabel>
                <RangeInput
                  as={InputCurrency}
                  defaultStartValue={formData.amountStart}
                  defaultEndValue={formData.amountEnd}
                  onChange={({ startValue, endValue }) =>
                    setFormData((state) => ({ ...state, amountStart: startValue, amountEnd: endValue }))
                  }
                  InputRightElement={<InputRightElement fontSize="xs">R$</InputRightElement>}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Valor final
                </FormLabel>
                <RangeInput
                  as={InputCurrency}
                  defaultStartValue={formData.receivableAmountStart}
                  defaultEndValue={formData.receivableAmountEnd}
                  onChange={({ startValue, endValue }) =>
                    setFormData((state) => ({ ...state, receivableAmountStart: startValue, receivableAmountEnd: endValue }))
                  }
                  InputRightElement={<InputRightElement fontSize="xs">R$</InputRightElement>}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Modalidade de fatura
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.isUnified ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, isUnified: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="yes">Fatura unificada</option>
                  <option value="no">Fatura separada</option>
                </Select>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Método de recebimento da fatura
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.sendingMethod ?? []}
                  placeholder="Selecione"
                  options={invoiceReceiptMethods}
                  onChange={(sendingMethod) => setFormData((state) => ({ ...state, sendingMethod }))}
                  getOptionLabel={({ value }) => translator(value)}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Usina
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.powerPlant ?? []}
                  defaultOptions
                  loadOptions={handleLoadPowerPlants}
                  placeholder="Selecione"
                  onChange={(powerPlant) => setFormData((state) => ({ ...state, powerPlant }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ name }) => name}
                  isClearable={true}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Data de referência
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.referenceDateStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.referenceDateStart}
                    defaultEndDate={formData.referenceDateEnd}
                    onChange={(referenceDateStart, referenceDateEnd) =>
                      setFormData((state) => ({ ...state, referenceDateStart, referenceDateEnd }))
                    }
                  />
                  {formData.referenceDateStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          referenceDateStart: null,
                          referenceDateEnd: null,
                          referenceDateStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Data de emissão
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.issueDateStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.issueDateStart}
                    defaultEndDate={formData.issueDateEnd}
                    onChange={(issueDateStart, issueDateEnd) => setFormData((state) => ({ ...state, issueDateStart, issueDateEnd }))}
                  />
                  {formData.issueDateStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          issueDateStart: null,
                          issueDateEnd: null,
                          issueDateStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Data de vencimento
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.dueDateStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.dueDateStart}
                    defaultEndDate={formData.dueDateEnd}
                    onChange={(dueDateStart, dueDateEnd) => setFormData((state) => ({ ...state, dueDateStart, dueDateEnd }))}
                  />
                  {formData.issueDateStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          dueDateStart: null,
                          dueDateEnd: null,
                          dueDateStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Analizada por
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.analyzedBy ?? []}
                  defaultOptions
                  loadOptions={handleLoadUsers("analyzedBy")}
                  placeholder="Selecione"
                  onChange={(analyzedBy) => setFormData((state) => ({ ...state, analyzedBy }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ name }) => name}
                  isClearable={true}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Analizada em
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.analyzedAtStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.analyzedAtStart}
                    defaultEndDate={formData.analyzedAtEnd}
                    onChange={(analyzedAtStart, analyzedAtEnd) => setFormData((state) => ({ ...state, analyzedAtStart, analyzedAtEnd }))}
                  />
                  {formData.analyzedAtStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          analyzedAtStart: null,
                          analyzedAtEnd: null,
                          analyzedAtStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Criado por
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.createdBy ?? []}
                  defaultOptions
                  loadOptions={handleLoadUsers("createdBy")}
                  placeholder="Selecione"
                  onChange={(createdBy) => setFormData((state) => ({ ...state, createdBy }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ name }) => name}
                  isClearable={true}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Criado em
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.createdAtStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.createdAtStart}
                    defaultEndDate={formData.createdAtEnd}
                    onChange={(createdAtStart, createdAtEnd) => setFormData((state) => ({ ...state, createdAtStart, createdAtEnd }))}
                  />
                  {formData.createdAtStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          createdAtStart: null,
                          createdAtEnd: null,
                          createdAtStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Primeira fatura?
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.isFirstInvoiceIssue ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, isFirstInvoiceIssue: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="yes">Sim</option>
                  <option value="no">Não</option>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Primeiro boleto?
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.isFirstBankSlipIssue ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, isFirstBankSlipIssue: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="yes">Sim</option>
                  <option value="no">Não</option>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Código de barras CEMIG
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.distributorDigitableLine ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, distributorDigitableLine: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="yes">Com código de barras</option>
                  <option value="no">Sem código de barras</option>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Método de pagamento
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.distributorPaymentMethod ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, distributorPaymentMethod: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="unspecified">Não especificado</option>
                  <option value="pix">Pix</option>
                  <option value="direct_debit">Débito automático</option>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Em revisão?
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.inReview ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, inReview: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="yes">Sim</option>
                  <option value="no">Não</option>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Possui pagamento?
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.hasPayment ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, hasPayment: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="yes">Sim</option>
                  <option value="no">Não</option>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Paga em
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.paidAtStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.paidAtStart}
                    defaultEndDate={formData.paidAtEnd}
                    onChange={(paidAtStart, paidAtEnd) => setFormData((state) => ({ ...state, paidAtStart, paidAtEnd }))}
                  />
                  {formData.paidAtStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          paidAtStart: null,
                          paidAtEnd: null,
                          paidAtStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Finalizada em
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.finishedAtStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.finishedAtStart}
                    defaultEndDate={formData.finishedAtEnd}
                    onChange={(finishedAtStart, finishedAtEnd) => setFormData((state) => ({ ...state, finishedAtStart, finishedAtEnd }))}
                  />
                  {formData.finishedAtStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          finishedAtStart: null,
                          finishedAtEnd: null,
                          finishedAtStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Baixa renda?
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.isLowIncome ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, isLowIncome: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="yes">Sim</option>
                  <option value="no">Não</option>
                </Select>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Cobrança terceirizada?
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.sentToCollectionAgency ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, sentToCollectionAgency: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="yes">Sim</option>
                  <option value="no">Não</option>
                </Select>
              </FormControl>
            </GridItem>
          </Grid>

          <Divider my={4} />

          <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Enviada?
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.isMessaged ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, isMessaged: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="yes">Sim</option>
                  <option value="no">Não</option>
                </Select>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Último envio
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.messagedAtStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.messagedAtStart}
                    defaultEndDate={formData.messagedAtEnd}
                    onChange={(messagedAtStart, messagedAtEnd) => setFormData((state) => ({ ...state, messagedAtStart, messagedAtEnd }))}
                  />
                  {formData.messagedAtStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          messagedAtStart: null,
                          messagedAtEnd: null,
                          messagedAtStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
          </Grid>

          <HStack justifyContent="flex-end">
            <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdSearch} />} isLoading={isLoading} onClick={handleSubmit}>
              aplicar
            </Button>
          </HStack>
        </>
      )}
    </Box>
  );
};

export default Filters;
