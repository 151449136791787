import React, { useState, useCallback, useContext, memo, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Select,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";
import { Portal, PermissionedContainer } from "components";
import { DocumentHistory } from "containers";
import { useCustomToast } from "hooks";
import { api, translator } from "lib";
import { messages } from "consts";
import { MdAdd, MdClose, MdHistory, MdMoreHoriz, MdOutlineDelete, MdRefresh } from "react-icons/md";
import { BiMailSend } from "react-icons/bi";
import { HiOutlineDocumentText } from "react-icons/hi";
import { AppContext } from "AppProvider";
import { BsFileEarmarkPdf } from "react-icons/bs";
import CustomersDetailsContext from "../context";
import Status from "./status";
import { PrivateContext } from "pages/Private";
import AddContract from "./add";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { ContractsContext } from "./index";

const ListItem = memo(({ item }) => {
  const { _id } = useParams();
  const { refreshConsumerUnits } = useContext(CustomersDetailsContext);
  const { refreshContracts } = useContext(ContractsContext);
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const [isLoadingResend, setIsLoadingResend] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const { isOpen: isOpenCancelDialog, onOpen: onOpenCancelDialog, onClose: onCloseCancelDialog } = useDisclosure();
  const { isOpen: isOpenDeleteDialog, onOpen: onOpenDeleteDialog, onClose: onCloseDeleteDialog } = useDisclosure();
  const { isOpen: isOpenAddContract, onOpen: onOpenAddContract, onClose: onCloseAddContract } = useDisclosure();
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const [signer] = useMemo(() => item.signers, [item.signers]);
  const label = useMemo(() => (item.parent ? "aditivo" : "contrato"), [item.parent]);
  const toast = useCustomToast();

  const handleRefresh = useCallback(async () => {
    try {
      setIsLoadingRefresh(true);
      await api.post(`/private/customers/${_id}/contracts/${item._id}`);
      refreshContracts();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingRefresh(false);
    }
  }, [item._id, _id, toast, refreshContracts]);

  const handleResend = useCallback(async () => {
    try {
      setIsLoadingResend(true);
      await api.patch(`/private/customers/${_id}/contracts/${item._id}/resend`);
      toast({ description: messages.success.resendContract, status: "success", isClosable: true });
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingResend(false);
    }
  }, [item._id, _id, toast]);

  const handleDownload = useCallback(async () => {
    try {
      setIsLoadingDownload(true);
      const response = await api({
        method: "post",
        url: `/private/customers/${_id}/contracts/${item._id}/download`,
        responseType: "arraybuffer",
        headers: { Accept: "application/pdf" },
      });
      const file = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(file);
      window.open(url);
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingDownload(false);
    }
  }, [item._id, _id]);

  const handleCancel = useCallback(async () => {
    try {
      setIsLoadingDelete(true);
      await api.patch(`/private/customers/${_id}/contracts/${item._id}/cancel`);
      toast({ description: messages.success.cancelContract, status: "success", isClosable: true });
      refreshContracts();
      refreshConsumerUnits();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingDelete(false);
      onCloseCancelDialog();
    }
  }, [item._id, _id, refreshContracts, refreshConsumerUnits, onCloseCancelDialog]);

  const handleDeleteData = useCallback(async () => {
    try {
      setIsLoadingDelete(true);
      await api.delete(`/private/customers/${_id}/contracts/${item._id}`);
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      refreshContracts();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingDelete(false);
      onCloseDeleteDialog();
    }
  }, [item._id, _id, refreshContracts, onCloseDeleteDialog]);

  return (
    <>
      {!item.isActive && (
        <Box
          position="absolute"
          width="100%"
          height="100%"
          top="0"
          left="0"
          _light={{ bg: "white" }}
          _dark={{ bg: "gray.800" }}
          opacity="0.6"
          borderRadius="lg"
        />
      )}
      <Grid templateColumns="repeat(12, 1fr)" gap={4} p={item.parent ? "10px" : "20px"}>
        <GridItem as={HStack} colSpan={{ base: 12, lg: 6 }}>
          <Center
            bg={item.isActive ? "main.100" : "gray.100"}
            w={item.parent ? "35px" : "45px"}
            h={item.parent ? "35px" : "45px"}
            borderRadius="full"
          >
            <Icon as={item.parent ? IoDocumentAttachOutline : HiOutlineDocumentText} color={item.isActive ? "main.500" : "gray.500"} />
          </Center>
          <Box flex="1">
            <Heading size="md">{_.map(item.consumerUnits, "cemigInstallationNumber").join(", ")}</Heading>
            <Text fontSize="xs">{item.uuid}</Text>
          </Box>
        </GridItem>
        <GridItem as={HStack} justifyContent={{ base: "center", lg: "flex-end" }} colSpan={{ base: 12, lg: 6 }}>
          {item.type === "digital" && (
            <>
              {item.status === "waiting_signers" && <Send size={item.parent ? "xs" : "sm"} {...{ item, refreshContracts }} />}
              {item.status === "waiting_signatures" && (
                <Button
                  size={item.parent ? "xs" : "sm"}
                  variant="outline"
                  rightIcon={<Icon as={BiMailSend} />}
                  isLoading={isLoadingResend}
                  onClick={handleResend}
                >
                  reenviar {label}
                </Button>
              )}
            </>
          )}
          <Button
            size={item.parent ? "xs" : "sm"}
            variant="outline"
            rightIcon={<Icon as={BsFileEarmarkPdf} />}
            isLoading={isLoadingDownload}
            onClick={handleDownload}
          >
            visualizar
          </Button>
          <PermissionedContainer required={["customers.contracts.create", "customers.contracts.cancel", "customers.contracts.delete"]}>
            <Box>
              <Menu>
                <MenuButton as={Button} size={item.parent ? "xs" : "sm"} variant="outline" rightIcon={<Icon as={MdMoreHoriz} />}>
                  mais ações
                </MenuButton>
                <Portal>
                  <MenuList fontSize="sm">
                    {!item.parent && (
                      <PermissionedContainer required="customers.contracts.create">
                        <MenuItem onClick={onOpenAddContract}>
                          <HStack>
                            <Icon as={MdAdd} />
                            <Text>adicionar aditivo</Text>
                          </HStack>
                        </MenuItem>
                        <MenuDivider />
                      </PermissionedContainer>
                    )}
                    {!item.isClosed && (
                      <PermissionedContainer required="customers.contracts.cancel">
                        <MenuItem onClick={onOpenCancelDialog}>
                          <HStack>
                            <Icon as={MdClose} />
                            <Text>cancelar {label}</Text>
                          </HStack>
                        </MenuItem>
                        <MenuDivider />
                      </PermissionedContainer>
                    )}
                    <PermissionedContainer required="customers.contracts.delete">
                      <MenuItem onClick={onOpenDeleteDialog}>
                        <HStack>
                          <Icon as={MdOutlineDelete} />
                          <Text>excluir</Text>
                        </HStack>
                      </MenuItem>
                    </PermissionedContainer>
                  </MenuList>
                </Portal>
              </Menu>
            </Box>
          </PermissionedContainer>
          <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />
        </GridItem>
      </Grid>
      <Divider />
      <Box p={item.parent ? "10px" : "20px"}>
        <Grid templateColumns="repeat(12, 1fr)" gap={4}>
          <GridItem colSpan={{ base: 12, lg: 3 }}>
            <Text fontSize="xs">Status</Text>
            <HStack>
              <Status contract={item} />
              {!item.isClosed && (
                <IconButton
                  size="xs"
                  variant="outline"
                  icon={<Icon as={MdRefresh} />}
                  isLoading={isLoadingRefresh}
                  onClick={handleRefresh}
                />
              )}
            </HStack>
          </GridItem>
          <GridItem colSpan={{ base: 6, lg: 3 }}>
            <Text fontSize="xs">Assinante</Text>
            <Text fontSize="sm" fontWeight="semibold">
              {signer?.whatsAppNumber ?? signer?.email ?? "-"}
            </Text>
            {signer?.isDelivered === false && <Badge colorScheme="red">Não entregue</Badge>}
          </GridItem>
          <GridItem colSpan={{ base: 6, lg: 2 }}>
            <Text fontSize="xs">Tipo</Text>
            <Text fontSize="sm" fontWeight="semibold">
              Contrato {translator(item.type)}
            </Text>
          </GridItem>
          <GridItem colSpan={{ base: 6, lg: 2 }}>
            <Text fontSize="xs">Gerenciado?</Text>
            <Text fontSize="sm" fontWeight="semibold">
              {item.isManagedUUID ? "Sim" : "Não"}
            </Text>
          </GridItem>
          <GridItem colSpan={{ base: 6, lg: 2 }}>
            <Text fontSize="xs">Criado em</Text>
            <Text fontSize="sm" fontWeight="semibold">
              {moment(item.createdAt).format("DD/MM/YYYY [às] HH:mm")}
            </Text>
          </GridItem>
        </Grid>
      </Box>

      <AlertDialog isOpen={isOpenCancelDialog} onClose={onCloseCancelDialog} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Cancelar {label}?
            </AlertDialogHeader>
            <AlertDialogBody>
              Deseja realmente cancelar o {label}? Esta ação também cancelará o {label} na D4Sign caso seja um documento digital.
            </AlertDialogBody>
            <AlertDialogFooter as={HStack} justifyContent="flex-end">
              <Button size="sm" variant="outline" onClick={onCloseCancelDialog}>
                cancelar
              </Button>
              <Button size="sm" colorScheme="red" isLoading={isLoadingDelete} onClick={handleCancel}>
                continuar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog isOpen={isOpenDeleteDialog} onClose={onCloseDeleteDialog} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Cancelar {label}?
            </AlertDialogHeader>
            <AlertDialogBody>Deseja realmente excluir o {label}?</AlertDialogBody>
            <AlertDialogFooter as={HStack} justifyContent="flex-end">
              <Button size="sm" variant="outline" onClick={onCloseDeleteDialog}>
                cancelar
              </Button>
              <Button size="sm" colorScheme="red" isLoading={isLoadingDelete} onClick={handleDeleteData}>
                continuar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AddContract isOpen={isOpenAddContract} onClose={onCloseAddContract} consumerUnits={item.consumerUnits} parent={item._id} />

      <DocumentHistory path={`/private/contracts/${item._id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />
    </>
  );
});

const Send = ({ size, item, refreshContracts }) => {
  const { _id } = useParams();
  const { formData: customerFormData } = useContext(CustomersDetailsContext);
  const [formData, setFormData] = useState({});
  const [isLoadingSend, setIsLoadingSend] = useState(false);
  const { isOpen: isOpenSendDialog, onOpen: onOpenSendDialog, onClose: onCloseSendDialog } = useDisclosure();
  const toast = useCustomToast();
  const label = useMemo(() => (item.parent ? "aditivo" : "contrato"), [item.parent]);

  useEffect(() => {
    setFormData({ sendMethod: "email" });
  }, [isOpenSendDialog]);

  const handleSend = useCallback(async () => {
    try {
      onCloseSendDialog();
      setIsLoadingSend(true);
      await api.patch(`/private/customers/${_id}/contracts/${item._id}/send`, formData);
      toast({ description: messages.success.sendContract, status: "success", isClosable: true });
      refreshContracts();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingSend(false);
    }
  }, [item._id, refreshContracts, _id, formData, toast, onCloseSendDialog]);

  return (
    <>
      <Button size={size} colorScheme="main" rightIcon={<Icon as={BiMailSend} />} isLoading={isLoadingSend} onClick={onOpenSendDialog}>
        enviar {label}
      </Button>
      <AlertDialog isOpen={isOpenSendDialog} onClose={onCloseSendDialog} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Enviar {label}?
            </AlertDialogHeader>
            <AlertDialogBody>
              <Text>Deseja realmente enviar o {label} para o assinante?</Text>
              {customerFormData.phone1Category === "whatsapp" && (
                <PermissionedContainer required="customers.contracts.sendViaWhatsApp">
                  <FormControl mt="20px" isRequired={true}>
                    <FormLabel fontSize="sm">Forma de envio</FormLabel>
                    <Select
                      value={formData.sendMethod ?? ""}
                      onChange={({ target }) => setFormData((state) => ({ ...state, sendMethod: target.value }))}
                    >
                      <option value="email">E-mail</option>
                      <option value="whatsapp">WhatsApp</option>
                    </Select>
                  </FormControl>
                </PermissionedContainer>
              )}
            </AlertDialogBody>
            <AlertDialogFooter as={HStack} justifyContent="flex-end">
              <Button size="sm" variant="outline" onClick={onCloseSendDialog}>
                cancelar
              </Button>
              <Button size="sm" colorScheme="main" isLoading={isLoadingSend} onClick={handleSend}>
                enviar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ListItem;
