export const messages = {
  success: {
    saveData: "Registro salvo com sucesso!",
    deleteData: "Registro removido com sucesso!",
    cancelContract: "Contrato cancelado com sucesso!",
    requestCancel: "Solicitação de cancelamento enviada com sucesso!",
    requestUpdate: "Solicitação de atualização enviada com sucesso!",
    requestProcess: "Solicitação de processamento enviada com sucesso!",
    createContract: "Contrato criado com sucesso. Antes de enviar, confira as informações do contrato.",
    sendContract: "Contrato enviado com sucesso!",
    resendContract: "Contrato reenviado com sucesso!",
    sendInvoice: "Fatura enviada com sucesso!",
    passwordResetEmail: "E-mail enviado com sucesso!",
    updatePassword: "A senha foi alterada com sucesso!",
    invoiceCaptureStarted: "A captura de faturas foi iniciada com sucesso!",
    messagesSendStarted: "O envio das mensagens foi iniciado. Por favor, acompanhe o andamento através da aba de mensagens.",
    executedCron: "Cron executado com sucesso!",
  },
  error: {
    saveData: "Problemas ao salvar registro.",
    deleteData: "Problemas ao excluir registro.",
    cancelContract: "Problemas ao cancelar contrato.",
    required: "Este campo é obrigatório.",
    invalidEmail: "O e-mail informado é inválido.",
    invalidPhoneNumber: "O número de telefone informado é inválido.",
    invalidDate: "A data informada é inválida.",
    invalidDuration: "A duração informada é inválida.",
    greaterOrEqual: "O valor informado deve ser maior ou igual a ",
    lessOrEqual: "O valor informado deve ser menor ou igual a ",
    moreThan: "O valor informado deve ser maior que ",
    lessThan: "O valor informado deve ser menor que ",
    passwordsMustMatch: "As novas senhas informadas devem ser iguais.",
    invalidCemigNumber: "O número da CEMIG deve conter 10 dígitos.",
    invalidCemigNumberFirstDigit: "O número da CEMIG deve iniciar com o dígito ",
    invalidName: "O nome informado é inválido. Por favor informe o nome completo.",
    required: "Este campo é obrigatório.",
    invalidLatitudeLongitude: "A latitude/longitude informada é inválida.",
    minDueDate: "A data de vencimento não pode ser inferior ao dia de hoje.",
    maxLength: (length) => `O texto deve possuir no máximo ${length} caracteres.`,
  },
  warning: {
    deleteDataConfirm: "Deseja realmente excluir este registro?",
    closeDataConfirm: "Esta é uma ação irreversível. Você está realizando o fechamento deste registro. Deseja realmente continuar?",
  },
};
