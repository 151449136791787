import React, { Fragment, createContext, useMemo } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Center, Spinner } from "@chakra-ui/react";
import { useFetchData } from "hooks";
import { Home } from "./Home";
import { CustomersList, CustomersDetails } from "./Customers";
import { ConsumerUnitsList } from "./ConsumerUnits";
import { LeadsDetails, LeadsList } from "./Leads";
import Container from "./Container";
import { InvoicesDetails, InvoicesList } from "./Invoices";
import { ChargesList } from "./Charges";
import { DistributorMonitoringsList } from "./DistributorMonitorings";
import { MessagesList } from "./Messages";
import { PowerManagementList } from "./PowerManagement";
import { InvoiceCapturesDetails, InvoiceCapturesList } from "./InvoiceCaptures";
import { PaymentsList } from "./Payments";
import { ApprovalsList } from "./Approvals";
import { WebhooksList } from "./Webhooks";
import { CronsDetails, CronsList } from "./Crons";
import { AlertsList } from "./Alerts";
import Watchers from "watchers";
import * as Settings from "./Settings";

export const PrivateContext = createContext();

const Private = () => {
  const [currentUser, isLoadingCurrentUser, refreshCurrentUser] = useFetchData(useMemo(() => ({ path: "/private/current-user" }), []));

  if (!currentUser)
    return (
      <Center h="100vh">
        <Spinner />
      </Center>
    );
  return (
    <PrivateContext.Provider value={{ currentUser, isLoadingCurrentUser, refreshCurrentUser }}>
      <BrowserRouter>
        <Routes>
          <Route
            element={
              <Fragment>
                <Container />
                <Watchers />
              </Fragment>
            }
          >
            <Route index={true} path="home" element={<Home />} />

            <Route path="alerts">
              <Route index={true} element={<AlertsList />} />
            </Route>

            <Route path="customers">
              <Route index={true} element={<CustomersList />} />
              <Route path="new" element={<CustomersDetails />} />
              <Route path="edit/:_id" element={<CustomersDetails />} />
            </Route>

            <Route path="consumer-units">
              <Route index={true} element={<ConsumerUnitsList />} />
            </Route>

            <Route path="leads">
              <Route index={true} element={<LeadsList />} />
              <Route path="new" element={<LeadsDetails />} />
              <Route path="edit/:_id" element={<LeadsDetails />} />
            </Route>

            <Route path="charges">
              <Route index={true} element={<ChargesList />} />
            </Route>

            <Route path="payments">
              <Route index={true} element={<PaymentsList />} />
            </Route>

            <Route path="approvals">
              <Route index={true} element={<ApprovalsList />} />
            </Route>

            <Route path="webhooks">
              <Route index={true} element={<WebhooksList />} />
            </Route>

            <Route path="invoices">
              <Route index={true} element={<InvoicesList />} />
              <Route path="new" element={<InvoicesDetails />} />
              <Route path="details/:_id" element={<InvoicesDetails />} />
            </Route>

            <Route path="distributor-monitorings">
              <Route index={true} element={<DistributorMonitoringsList />} />
            </Route>

            <Route path="messages">
              <Route index={true} element={<MessagesList />} />
            </Route>

            <Route path="invoice-captures">
              <Route index={true} element={<InvoiceCapturesList />} />
              <Route path="details/:_id" element={<InvoiceCapturesDetails />} />
            </Route>

            <Route path="power-management">
              <Route index={true} element={<PowerManagementList />} />
            </Route>

            <Route path="users">
              <Route index={true} element={<Settings.Records.UsersList />} />
              <Route path="new" element={<Settings.Records.UsersDetails />} />
              <Route path="edit/:_id" element={<Settings.Records.UsersDetails />} />
            </Route>

            <Route path="power-plants">
              <Route index={true} element={<Settings.Records.PowerPlantsList />} />
              <Route path="new" element={<Settings.Records.PowerPlantsDetails />} />
              <Route path="edit/:_id" element={<Settings.Records.PowerPlantsDetails />} />
            </Route>

            <Route path="exhibitions">
              <Route index={true} element={<Settings.Records.ExhibitionsList />} />
              <Route path="new" element={<Settings.Records.ExhibitionsDetails />} />
              <Route path="edit/:_id" element={<Settings.Records.ExhibitionsDetails />} />
            </Route>

            <Route path="segments">
              <Route index={true} element={<Settings.Records.SegmentsList />} />
              <Route path="new" element={<Settings.Records.SegmentsDetails />} />
              <Route path="edit/:_id" element={<Settings.Records.SegmentsDetails />} />
            </Route>

            <Route path="statuses">
              <Route index={true} element={<Settings.Records.StatusesList />} />
            </Route>

            <Route path="expiration-groups">
              <Route index={true} element={<Settings.Records.ExpirationGroupsList />} />
              <Route path="new" element={<Settings.Records.ExpirationGroupsDetails />} />
              <Route path="edit/:_id" element={<Settings.Records.ExpirationGroupsDetails />} />
            </Route>

            <Route path="sending-groups">
              <Route index={true} element={<Settings.Records.SendingGroupsList />} />
              <Route path="new" element={<Settings.Records.SendingGroupsDetails />} />
              <Route path="edit/:_id" element={<Settings.Records.SendingGroupsDetails />} />
            </Route>

            <Route path="promotions">
              <Route index={true} element={<Settings.Records.PromotionsList />} />
              <Route path="new" element={<Settings.Records.PromotionsDetails />} />
              <Route path="edit/:_id" element={<Settings.Records.PromotionsDetails />} />
            </Route>

            <Route path="user-groups">
              <Route index={true} element={<Settings.Records.UserGroupsList />} />
              <Route path="new" element={<Settings.Records.UserGroupsDetails />} />
              <Route path="edit/:_id" element={<Settings.Records.UserGroupsDetails />} />
            </Route>

            <Route path="tariff-groups">
              <Route index={true} element={<Settings.Records.TariffGroupsList />} />
              <Route path="new" element={<Settings.Records.TariffGroupsDetails />} />
              <Route path="edit/:_id" element={<Settings.Records.TariffGroupsDetails />} />
            </Route>

            <Route path="tariff-bases">
              <Route index={true} element={<Settings.Records.TariffBasesList />} />
              <Route path="new" element={<Settings.Records.TariffBasesDetails />} />
              <Route path="edit/:_id" element={<Settings.Records.TariffBasesDetails />} />
            </Route>

            <Route path="invoice-templates">
              <Route index={true} element={<Settings.Records.InvoiceTemplatesList />} />
              <Route path="new" element={<Settings.Records.InvoiceTemplatesDetails />} />
              <Route path="edit/:_id" element={<Settings.Records.InvoiceTemplatesDetails />} />
            </Route>

            <Route path="faq">
              <Route index={true} element={<Settings.Records.FaqList />} />
              <Route path="new" element={<Settings.Records.FaqDetails />} />
              <Route path="edit/:_id" element={<Settings.Records.FaqDetails />} />
            </Route>

            <Route path="crons">
              <Route index={true} element={<CronsList />} />
              <Route path="detailing/:topic" element={<CronsDetails />} />
            </Route>

            <Route path="settings">
              <Route index={true} element={<Settings.Container />} />
            </Route>
          </Route>

          <Route path="*" element={<Navigate to="/home" replace={true} />} />
        </Routes>
      </BrowserRouter>
    </PrivateContext.Provider>
  );
};

export default Private;
