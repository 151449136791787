import React, { useContext, useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import InputMask from "react-input-mask";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { api, yup, EventEmitter } from "lib";
import { useCustomToast } from "hooks";
import { messages } from "consts";
import InvoicesDetailsContext from "./context";

const ChargeCreate = ({ isOpen, onClose }) => {
  const { _id } = useParams();
  const { formData: invoiceFormData, refreshData } = useContext(InvoicesDetailsContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const toast = useCustomToast();

  useEffect(() => {
    setFormData((state) => ({ ...state, dueDate: invoiceFormData.dueDate }));
    setFormErrors({});
  }, [isOpen, invoiceFormData.dueDate]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.put(`/private/invoices/${_id}/charges`, data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        EventEmitter.emit("charges.refresh");
        refreshData();
        onClose();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        dueDate: yup
          .date()
          .typeError(messages.error.invalidDate)
          .required(messages.error.required)
          .min(moment().endOf("day").toDate(), messages.error.minDueDate),
      });
      const data = { invoiceId: _id, dueDate: moment(formData.dueDate, "DD/MM/YYYY").endOf("day").toDate() };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>Emitir cobrança</AlertDialogHeader>
        <AlertDialogBody as={VStack} spacing={4}>
          <Text>Deseja realmente emitir uma cobrança para a fatura atual?</Text>
          <FormControl isRequired={true} isInvalid={formErrors.dueDate}>
            <FormLabel fontSize="sm">Vencimento</FormLabel>
            <Input
              as={InputMask}
              mask="99/99/9999"
              value={formData.dueDate ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, dueDate: target.value }))}
            />
            <FormErrorMessage>{formErrors.dueDate}</FormErrorMessage>
          </FormControl>
        </AlertDialogBody>
        <AlertDialogFooter as={HStack} justify="flex-end">
          <Button size="sm" variant="outline" onClick={onClose}>
            cancelar
          </Button>
          <Button size="sm" colorScheme="main" isLoading={isLoadingSaveData} onClick={handleSubmit}>
            confirmar
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ChargeCreate;
