import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { useCustomToast, useFetchData, useQueryParams, useWatcherZIndex } from "hooks";
import DistributorMonitoringsDetailsContext from "./context";
import General from "./general";
import { MdRefresh } from "react-icons/md";
import { TbArchive } from "react-icons/tb";
import { api, EventEmitter, yup } from "lib";
import { messages } from "consts";

const queryParamsKey = "distributor_monitoring_id";

export const DistributorMonitoringsDetails = () => {
  const { queryParams, setQueryParams } = useQueryParams();
  const _id = useMemo(() => queryParams[queryParamsKey], [queryParams[queryParamsKey]]);
  const zIndex = useWatcherZIndex(queryParamsKey);
  const [data, isLoadingData, refreshData] = useFetchData(useMemo(() => ({ path: `/private/distributor-monitorings/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setFormData(data ?? {});
  }, [data]);

  useEffect(() => {
    if (_.isString(_id)) onOpen();
    else onClose();
  }, [_id]);

  const handleClose = useCallback(() => {
    delete queryParams[queryParamsKey];
    setQueryParams(queryParams, { replace: true });
  }, [queryParams]);

  return (
    <DistributorMonitoringsDetailsContext.Provider value={{ _id, formData, isLoadingData, refreshData }}>
      <Drawer isOpen={isOpen} size="md" placement="right" blockScrollOnMount={false} onClose={handleClose}>
        <DrawerOverlay zIndex={zIndex.overlay} />
        <DrawerContent zIndex={zIndex.content}>
          <DrawerHeader as={HStack}>
            <Text flex="1">Monitoramento CEMIG</Text>
            {formData.status === "pending" && <Archive />}
            <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingData} onClick={refreshData} />
          </DrawerHeader>
          <Divider />
          <DrawerBody pt="20px">
            <General />
          </DrawerBody>
          <Divider />
          <DrawerFooter>
            <Button size="sm" variant="outline" onClick={handleClose}>
              fechar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </DistributorMonitoringsDetailsContext.Provider>
  );
};

const Archive = () => {
  const { _id, refreshData } = useContext(DistributorMonitoringsDetailsContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    setFormData({ status: "archived", comments: "" });
    setFormErrors({});
  }, [isOpen]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.patch(`/private/distributor-monitorings/${_id}`, data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        onClose();
        refreshData();
        EventEmitter.emit("distributorMonitorings.refresh");
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, onClose]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        comments: yup.string().max(100, messages.error.maxLength(100)).required(messages.error.required),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <Fragment>
      <Button size="sm" variant="outline" leftIcon={<Icon as={TbArchive} />} isLoading={isLoadingSaveData} onClick={onOpen}>
        arquivar
      </Button>
      <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            <Text>Atenção</Text>
            <Text fontSize="md" fontWeight="light">
              Deseja realmente arquivar o monitoramento atual?
            </Text>
          </AlertDialogHeader>
          <AlertDialogBody>
            <FormControl isRequired={true} isInvalid={formErrors.comments}>
              <HStack mb="5px" justifyContent="space-between">
                <FormLabel mb="0">Observações</FormLabel>
                <Text fontSize="xs" color={_.size(formData.comments) > 100 && "red.500"}>
                  {_.size(formData.comments)}/100
                </Text>
              </HStack>
              <Textarea
                h="100px"
                value={formData.comments || ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, comments: target.value }))}
              />
              <FormErrorMessage>{formErrors.comments}</FormErrorMessage>
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" onClick={onClose}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="green" onClick={handleSubmit} isLoading={isLoadingSaveData}>
              continuar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Fragment>
  );
};
