import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  InputRightElement,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { api, translator, setOneOrMany } from "lib";
import { AsyncSelect, SyncSelect, RangeDateInput, InputCurrency, RangeInput } from "components";
import { statuses } from "consts";
import { useCacheState, useFetchData, useStickyState } from "hooks";
import { MdClose, MdSearch, MdRefresh } from "react-icons/md";
import { HiOutlineFilter } from "react-icons/hi";
import { BiCog } from "react-icons/bi";

let loadUsersTimeout = {};
let loadCustomersTimeout;

const Filters = ({ onQuery, onRefresh, onPage, isLoading, onTableDrawer }) => {
  const location = useLocation();
  const [query, setQuery] = useStickyState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.query"),
        defaultValue: location.state ?? {
          status: statuses.distributorMonitorings.filter((o) => o.value === "pending"),
          isUnified: "no",
        },
        useCached: _.isObject(location.state) === false,
        _v: 2,
        processor: (data) => ({
          ...data,
          paidAtStart: data.paidAtStart && moment(data.paidAtStart).toDate(),
          paidAtEnd: data.paidAtEnd && moment(data.paidAtEnd).toDate(),
          archivedAtStart: data.archivedAtStart && moment(data.archivedAtStart).toDate(),
          archivedAtEnd: data.archivedAtEnd && moment(data.archivedAtEnd).toDate(),
        }),
      }),
      [location.pathname, location.state]
    )
  );
  const [isOpen, setIsOpen] = useCacheState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.isOpen"),
        defaultValue: false,
      }),
      [location.pathname]
    )
  );
  const [formData, setFormData] = useState(query);
  const [isFiltering, setIsFiltering] = useState(false);
  const backgroundColor = useColorModeValue("gray.50", "blackAlpha.300");
  const containerProps = useMemo(
    () => isOpen && { padding: { base: "10px", lg: "20px" }, backgroundColor, marginBottom: 8 },
    [isOpen, backgroundColor]
  );
  const [consumerUnitStatuses, isLoadingConsumerUnitStatuses, refreshConsumerUnitStatuses] = useFetchData(
    useMemo(
      () => ({
        path: "/private/statuses",
        params: {
          query: { moduleName: "ConsumerUnit", isActive: true },
          sort: { ordination: 1 },
          perPage: -1,
          isAutocomplete: true,
        },
      }),
      []
    )
  );

  useEffect(() => {
    const response = {};
    const toObjectId = (data) => _.map(data, (o) => ["@ObjectId", o._id]);
    setOneOrMany("distributorMonitoring.referenceCode", query.referenceCode, response);
    setOneOrMany("distributorMonitoring.installationNumber", query.installationNumber, response);
    if (query.status?.length) _.set(response, "distributorMonitoring.status.$in", _.map(query.status, "value"));
    if (query.paidAtStart) _.set(response, "distributorMonitoring.paidAt.$gte", ["@ISODate", query.paidAtStart]);
    if (query.paidAtEnd) _.set(response, "distributorMonitoring.paidAt.$lte", ["@ISODate", query.paidAtEnd]);
    if (query.archivedAtStart) _.set(response, "distributorMonitoring.archivedAt.$gte", ["@ISODate", query.archivedAtStart]);
    if (query.archivedAtEnd) _.set(response, "distributorMonitoring.archivedAt.$lte", ["@ISODate", query.archivedAtEnd]);
    if (query.archivedBy?.length) _.set(response, "distributorMonitoring.archivedBy.$in", toObjectId(query.archivedBy));
    if (query.elapsedDaysStart) _.set(response, "distributorMonitoring.elapsedDays.$gte", query.elapsedDaysStart);
    if (query.elapsedDaysEnd) _.set(response, "distributorMonitoring.elapsedDays.$lte", query.elapsedDaysEnd);

    if (query.customerId?.length) _.set(response, "customer._id.$in", toObjectId(query.customerId));
    if (query.customerConsultant?.length) _.set(response, "customer.consultant.$in", toObjectId(query.customerConsultant));

    if (query.consumerUnitStatus?.length) _.set(response, "consumerUnit.status.$in", toObjectId(query.consumerUnitStatus));
    if (query.checkedElectricityAverageSpentInKwhStart)
      _.set(response, "consumerUnit.checkedElectricityAverageSpentInKwh.$gte", query.checkedElectricityAverageSpentInKwhStart);
    if (query.checkedElectricityAverageSpentInKwhEnd)
      _.set(response, "consumerUnit.checkedElectricityAverageSpentInKwh.$lte", query.checkedElectricityAverageSpentInKwhEnd);

    if (query.invoiceStatus?.length) _.set(response, "invoice.status.$in", _.map(query.invoiceStatus, "value"));
    if (query.isUnified?.length) _.set(response, "invoice.isUnified", query.isUnified === "yes");
    setIsFiltering(Object.keys(response).length > 0);
    onQuery(response);
    onPage(0);
  }, [onQuery, onPage, query, location.state]);

  const handleLoadUsers = useCallback((key, search, cb) => {
    clearTimeout(loadUsersTimeout[key]);
    loadUsersTimeout[key] = setTimeout(async () => {
      const response = await api.post("/private/users", { search, perPage: 20, isAutocomplete: true, withSystem: true });
      cb(_.map(response?.data, ({ _id, name }) => ({ _id, name })));
    }, 1000);
  }, []);

  const handleLoadCustomers = useCallback((search, cb) => {
    clearTimeout(loadCustomersTimeout);
    loadCustomersTimeout = setTimeout(async () => {
      const response = await api.post("/private/customers", { search, perPage: 20, isAutocomplete: true });
      cb(_.map(response?.data, ({ _id, name }) => ({ _id, name })));
    }, 1000);
  }, []);

  const handleSubmit = useCallback(() => {
    setQuery(formData);
  }, [setQuery, formData]);

  const handleClean = useCallback(() => {
    setQuery({});
    setFormData({});
  }, [setQuery, setFormData]);

  return (
    <Box {...containerProps} borderRadius="lg" transition="400ms">
      <Grid templateColumns="repeat(12,1fr)" gap={2} mb={8}>
        <GridItem as={HStack} justifyContent="space-between" colSpan={{ base: 12, lg: 12 }}>
          <HStack>
            <Button
              colorScheme={isFiltering ? "main" : "gray"}
              variant="outline"
              rightIcon={<Icon as={HiOutlineFilter} />}
              onClick={() => setIsOpen((state) => !state)}
            >
              filtros
            </Button>
            {isFiltering && (
              <Button variant="outline" rightIcon={<Icon as={MdClose} />} onClick={handleClean}>
                limpar filtros
              </Button>
            )}
          </HStack>
          <HStack>
            <IconButton variant="outline" icon={<Icon as={MdRefresh} />} fontSize="sm" isLoading={isLoading} onClick={onRefresh} />
            <IconButton fontSize="sm" variant="outline" icon={<Icon as={BiCog} />} onClick={onTableDrawer} />
          </HStack>
        </GridItem>
      </Grid>
      {isOpen && (
        <>
          <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Status do monitoramento
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  withSelectAll
                  value={formData.status ?? []}
                  placeholder="Selecione"
                  options={statuses.distributorMonitorings}
                  onChange={(status) => setFormData((state) => ({ ...state, status }))}
                  formatOptionLabel={({ color, value, label }) => (
                    <HStack>
                      <Box bg={color} w="10px" h="10px" borderRadius="full" />
                      <Text>{label || translator(value)}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Status da fatura
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  withSelectAll
                  value={formData.invoiceStatus ?? []}
                  placeholder="Selecione"
                  options={statuses.invoices}
                  onChange={(invoiceStatus) => setFormData((state) => ({ ...state, invoiceStatus }))}
                  formatOptionLabel={({ color, value, label }) => (
                    <HStack>
                      <Box bg={color} w="10px" h="10px" borderRadius="full" />
                      <Text>{label || translator(value)}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Status da UC
                </FormLabel>
                <HStack>
                  <SyncSelect
                    size="sm"
                    variant="filled"
                    isMulti
                    withSelectAll
                    value={formData.consumerUnitStatus ?? []}
                    options={consumerUnitStatuses?.data || []}
                    placeholder="Selecione"
                    onChange={(consumerUnitStatus) => setFormData((state) => ({ ...state, consumerUnitStatus }))}
                    getOptionValue={({ _id }) => _id}
                    formatOptionLabel={({ colorScheme, label, title }) => (
                      <HStack>
                        <Box bg={`${colorScheme}.500`} w="10px" h="10px" borderRadius="full" />
                        <Text>{label || title}</Text>
                      </HStack>
                    )}
                    isClearable={true}
                  />
                  <IconButton
                    size="sm"
                    variant="outline"
                    icon={<Icon as={MdRefresh} />}
                    isLoading={isLoadingConsumerUnitStatuses}
                    onClick={refreshConsumerUnitStatuses}
                  />
                </HStack>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Consultor
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.customerConsultant ?? []}
                  defaultOptions
                  loadOptions={(search, cb) => handleLoadUsers("customerConsultant", search, cb)}
                  placeholder="Selecione"
                  onChange={(customerConsultant) => setFormData((state) => ({ ...state, customerConsultant }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ name }) => name}
                  isClearable={true}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Cliente
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.customerId ?? []}
                  defaultOptions
                  loadOptions={handleLoadCustomers}
                  placeholder="Selecione"
                  onChange={(customerId) => setFormData((state) => ({ ...state, customerId }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ name }) => name}
                  isClearable={true}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Cod. referência
                </FormLabel>
                <Input
                  size="sm"
                  variant="filled"
                  value={formData.referenceCode ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, referenceCode: target.value }))}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  N° de instalação
                </FormLabel>
                <Input
                  size="sm"
                  variant="filled"
                  value={formData.installationNumber ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, installationNumber: target.value }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Média de consumo corrigida
                </FormLabel>
                <RangeInput
                  as={InputCurrency}
                  precision="0"
                  defaultStartValue={formData.checkedElectricityAverageSpentInKwhStart}
                  defaultEndValue={formData.checkedElectricityAverageSpentInKwhEnd}
                  onChange={({ startValue, endValue }) =>
                    setFormData((state) => ({
                      ...state,
                      checkedElectricityAverageSpentInKwhStart: startValue,
                      checkedElectricityAverageSpentInKwhEnd: endValue,
                    }))
                  }
                  InputRightElement={<InputRightElement fontSize="xs">kWh</InputRightElement>}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Modalidade de fatura
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.isUnified ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, isUnified: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="yes">Fatura unificada</option>
                  <option value="no">Fatura separada</option>
                </Select>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Dias decorridos
                </FormLabel>
                <RangeInput
                  as={InputCurrency}
                  precision="0"
                  defaultStartValue={formData.elapsedDaysStart}
                  defaultEndValue={formData.elapsedDaysEnd}
                  onChange={({ startValue, endValue }) =>
                    setFormData((state) => ({
                      ...state,
                      elapsedDaysStart: startValue,
                      elapsedDaysEnd: endValue,
                    }))
                  }
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Pago em
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.paidAtStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.paidAtStart}
                    defaultEndDate={formData.paidAtEnd}
                    onChange={(paidAtStart, paidAtEnd) => setFormData((state) => ({ ...state, paidAtStart, paidAtEnd }))}
                  />
                  {formData.paidAtStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          paidAtStart: null,
                          paidAtEnd: null,
                          paidAtStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Arquivada por
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.archivedBy ?? []}
                  defaultOptions
                  loadOptions={(search, cb) => handleLoadUsers("archivedBy", search, cb)}
                  placeholder="Selecione"
                  onChange={(archivedBy) => setFormData((state) => ({ ...state, archivedBy }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ name }) => name}
                  isClearable={true}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Arquivado em
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.archivedAtStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.archivedAtStart}
                    defaultEndDate={formData.archivedAtEnd}
                    onChange={(archivedAtStart, archivedAtEnd) => setFormData((state) => ({ ...state, archivedAtStart, archivedAtEnd }))}
                  />
                  {formData.archivedAtStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          archivedAtStart: null,
                          archivedAtEnd: null,
                          archivedAtStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
          </Grid>

          <HStack justifyContent="flex-end">
            <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdSearch} />} isLoading={isLoading} onClick={handleSubmit}>
              aplicar
            </Button>
          </HStack>
        </>
      )}
    </Box>
  );
};

export default Filters;
