import React, { useCallback, useContext, useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import InputMask from "react-input-mask";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Input,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useCustomToast } from "hooks";
import { EventEmitter, api, yup } from "lib";
import { messages } from "consts";
import Context from "./context";

export const Dialog = () => {
  const { charge, isOpenUpdateDialog, onCloseUpdateDialog } = useContext(Context);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const toast = useCustomToast();

  useEffect(() => {
    setFormData({
      dueDate: moment(charge.dueDate).format("DD/MM/YYYY"),
      isUnified: charge.invoice.isUnified ? "yes" : "no",
    });
    setFormErrors({});
  }, [isOpenUpdateDialog, charge]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.patch(`/private/invoices/${charge.invoice._id}/charges`, data);
        toast({ description: messages.success.requestUpdate, status: "success", isClosable: true });
        EventEmitter.emit("charges.refresh");
        onCloseUpdateDialog();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [charge.invoice._id]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        dueDate: yup
          .date()
          .typeError(messages.error.invalidDate)
          .required(messages.error.required)
          .min(moment().startOf("day").toDate(), messages.error.minDueDate),
        isUnified: yup.boolean().required(messages.error.required),
      });
      const data = {
        dueDate: moment(formData.dueDate, "DD/MM/YYYY").endOf("day").toDate(),
        isUnified: formData.isUnified === "yes",
      };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <AlertDialog isOpen={isOpenUpdateDialog} onClose={onCloseUpdateDialog} isCentered>
      <AlertDialogOverlay>
        <AlertDialogContent onDoubleClick={(e) => e.stopPropagation()}>
          <AlertDialogHeader>
            <Heading size="sm">Atualizar cobrança</Heading>
            <Text fontSize="sm" fontWeight="light">
              Deseja realmente atualizar esta cobrança?
            </Text>
          </AlertDialogHeader>
          <AlertDialogBody as={VStack} spacing={4}>
            <FormControl isRequired={true} isInvalid={formErrors.dueDate}>
              <FormLabel fontSize="sm">Vencimento</FormLabel>
              <Input
                as={InputMask}
                mask="99/99/9999"
                value={formData.dueDate ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, dueDate: target.value }))}
              />
              <FormErrorMessage>{formErrors.dueDate}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired={true} isInvalid={formErrors.isUnified} isDisabled={charge.invoice.type === "manual"}>
              <FormLabel fontSize="sm">Modalidade de fatura</FormLabel>
              <Select
                value={formData.isUnified ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, isUnified: target.value }))}
              >
                <option value="yes">Fatura unificada</option>
                <option value="no">Fatura separada</option>
              </Select>
              <FormErrorMessage>{formErrors.isUnified}</FormErrorMessage>
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justifyContent="flex-end">
            <Button size="sm" variant="outline" onClick={onCloseUpdateDialog}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="yellow" isLoading={isLoadingSaveData} onClick={handleSubmit}>
              atualizar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default Dialog;
