import moment from "moment";
import { locale, percent, translator } from "lib";
import { TbExternalLink } from "react-icons/tb";
import { Button, Icon } from "@chakra-ui/react";

const defaultColumns = [
  {
    accessor: "referenceCode",
    title: "Cod. referência",
    isVisible: true,
    formatter: ({ referenceCode }) => referenceCode,
  },
  {
    accessor: "installationNumber",
    title: "Num. instalação",
    isVisible: true,
    render: ({ _id, installationNumber }, { handleNavigateToCustomer, isLoadingNavigate }) => (
      <Button
        size="xs"
        variant="outline"
        rightIcon={<Icon as={TbExternalLink} />}
        onClick={() => handleNavigateToCustomer({ _id, installationNumber })}
        isLoading={isLoadingNavigate[_id]}
      >
        {installationNumber}
      </Button>
    ),
    exporter: ({ installationNumber }) => installationNumber,
  },
  {
    accessor: "modality",
    title: "Modalidade",
    isVisible: true,
    formatter: ({ modality }) => translator(modality || "-"),
  },
  {
    accessor: "referenceDate",
    title: "Dt. referência",
    isVisible: true,
    formatter: ({ referenceDate }) => moment(referenceDate).format("MM/YYYY"),
  },
  {
    accessor: "quota",
    title: "Quota",
    isVisible: true,
    formatter: ({ quota }) => percent(quota, { precision: 5 }),
  },
  {
    accessor: "previousPowerQttBalanceInKwh",
    title: "Saldo anterior (kWh)",
    isVisible: true,
    render: ({ previousPowerQttBalanceInKwh }) => locale(previousPowerQttBalanceInKwh, { precision: 0 }),
    exporter: ({ previousPowerQttBalanceInKwh }) => locale(previousPowerQttBalanceInKwh, { precision: 0, compact: true }),
  },
  {
    accessor: "currentPowerQttBalanceInKwh",
    title: "Saldo atual (kWh)",
    isVisible: true,
    render: ({ currentPowerQttBalanceInKwh }) => locale(currentPowerQttBalanceInKwh, { precision: 0 }),
    exporter: ({ currentPowerQttBalanceInKwh }) => locale(currentPowerQttBalanceInKwh, { precision: 0, compact: true }),
  },
  {
    accessor: "consumedPowerQttInKwh",
    title: "Energia consumida (kWh)",
    isVisible: true,
    render: ({ consumedPowerQttInKwh }) => locale(consumedPowerQttInKwh, { precision: 0 }),
    exporter: ({ consumedPowerQttInKwh }) => locale(consumedPowerQttInKwh, { precision: 0, compact: true }),
  },
  {
    accessor: "generatedPowerQttInKwh",
    title: "Energia gerada (kWh)",
    isVisible: true,
    render: ({ generatedPowerQttInKwh }) => locale(generatedPowerQttInKwh, { precision: 0 }),
    exporter: ({ generatedPowerQttInKwh }) => locale(generatedPowerQttInKwh, { precision: 0, compact: true }),
  },
  {
    accessor: "compensatedPowerQttInKwh",
    title: "Energia compensada (kWh)",
    isVisible: true,
    render: ({ compensatedPowerQttInKwh }) => locale(compensatedPowerQttInKwh, { precision: 0 }),
    exporter: ({ compensatedPowerQttInKwh }) => locale(compensatedPowerQttInKwh, { precision: 0, compact: true }),
  },
  {
    accessor: "receivedPowerQttInKwh",
    title: "Energia recebida (kWh)",
    isVisible: true,
    render: ({ receivedPowerQttInKwh }) => locale(receivedPowerQttInKwh, { precision: 0 }),
    exporter: ({ receivedPowerQttInKwh }) => locale(receivedPowerQttInKwh, { precision: 0, compact: true }),
  },
  {
    accessor: "transferredPowerQttInKwh",
    title: "Energia transferida (kWh)",
    isVisible: true,
    render: ({ transferredPowerQttInKwh }) => locale(transferredPowerQttInKwh, { precision: 0 }),
    exporter: ({ transferredPowerQttInKwh }) => locale(transferredPowerQttInKwh, { precision: 0, compact: true }),
  },
  {
    accessor: "expiredPowerQttBalanceInKwh",
    title: "Saldo expirado (kWh)",
    isVisible: true,
    render: ({ expiredPowerQttBalanceInKwh }) => locale(expiredPowerQttBalanceInKwh, { precision: 0 }),
    exporter: ({ expiredPowerQttBalanceInKwh }) => locale(expiredPowerQttBalanceInKwh, { precision: 0, compact: true }),
  },
  {
    accessor: "nextPowerQttBalanceInKwhToExpire",
    title: "Próx. saldo a expirar (kWh)",
    isVisible: true,
    render: ({ nextPowerQttBalanceInKwhToExpire }) => locale(nextPowerQttBalanceInKwhToExpire, { precision: 0 }),
    exporter: ({ nextPowerQttBalanceInKwhToExpire }) => locale(nextPowerQttBalanceInKwhToExpire, { precision: 0, compact: true }),
  },
  {
    accessor: "nextPowerQttBalanceExpireDate",
    title: "Dt. próx. saldo a expirar",
    isVisible: true,
    formatter: ({ nextPowerQttBalanceExpireDate }) =>
      nextPowerQttBalanceExpireDate ? moment(nextPowerQttBalanceExpireDate).format("MM/YYYY") : "-",
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    formatter: ({ createdBy }) => createdBy?.name ?? "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ updatedAt }) => moment(updatedAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
